import React, { useEffect, useState } from 'react';
import { Col, Row, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Input, Button, UncontrolledTooltip } from "reactstrap";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { toast } from 'react-toastify';
import { getSchools, getSchool, deleteSchool } from '../../services/schools';
import { Link, useHistory } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import NotificationsService from '../../components/Common/toaster';

const SchoolList = ({ title }) => {
  let typingTimeout = null;
  const history = useHistory()
  const [first, setfirst] = useState([])
  const [page, setpage] = useState(0)
  const [sizePerPage, setsizePerPage] = useState(10)
  const [load, setLoad] = useState(true)
  const [pageloading, setpageloading] = useState(false)
  const [deleteSchoolDialog, setDeleteSchoolDialog] = useState(false);
  const [currentSchool, setCurrentSchool] = useState({});


  const [lazyParams, setLazyParams] = useState({
    searchText: "",
    first: 0,
    rows: 5,
    page: 1,
    sortField: "",
    sortOrder: 0,
  });

  // React=query clinet to refeatch data, etc.. oprations
  const queryClient = useQueryClient()

  //get Code Queries
  const { data: results, refetch: reload, isLoading } = useQuery(['schools', lazyParams], () => getSchool(lazyParams), {
    keepPreviousData: true,
    onError: (error) => {
      toast.error(error.message);

    },
  })


  // Mutation for delete school data
  const { mutateAsync: deleteSchoolMuted, isLoading: isSchoolDeleting } = useMutation(deleteSchool, {

    onSuccess: (data) => {
      const previousValue = queryClient.getQueryData(['schools', lazyParams], { exact: false });
      const updatedValue = [...previousValue.results];
      const removeDeleted = updatedValue.filter(
        eachValue => eachValue.id !== data.id);
      previousValue.results = removeDeleted
      queryClient.setQueryData("schools", previousValue);
      NotificationsService.success("School and associate data removed successfully")
      // queryClient.invalidateQueries('users')

    },
    onError: (error) => {
      NotificationsService.error(error.message);

    }
  })



  const onPage = (event) => {
    let data = event
    data.page = event.page + 1
    let _lazyParams = { ...lazyParams, ...data };
    setLazyParams(_lazyParams);
  }

  // useEffect(() => {
  //   getproducts()
  // }, [page, sizePerPage])



  const confirmDeleteSchool = (studentData) => {
    setCurrentSchool(studentData);
    setDeleteSchoolDialog(true);
  }



  const actionBodyTemplate = (rowData, { rowIndex }) => {
    return <React.Fragment>
      <div className="d-flex">

        <Link to={"/schools/" + rowData?.id} className="mr-3 text-primary" id="edit1"><i className="ri-list-settings-line font-size-18"></i></Link>
        <UncontrolledTooltip placement="auto" target="edit1">
          Edit
        </UncontrolledTooltip >

        <button
          className="text-danger"
          style={{ background: "transparent", border: "none", cursor: "pointer" }}
          id={"delete1" + rowIndex}
          disabled={isSchoolDeleting}
          onClick={() => confirmDeleteSchool(rowData)} >
          {!isSchoolDeleting ? <i className="ri-delete-bin-6-line font-size-18"></i> : <i class="ri-loader-2-line"></i>}

        </button>
        <UncontrolledTooltip placement="auto" target={"delete1" + rowIndex}>
          Delete
        </UncontrolledTooltip >


      </div>
    </React.Fragment>
    // <button className="btn-outline-primary btn-rounded waves-effect add-btn float-right ml-auto float-right"
    // onClick={() => { window.$('#Create').modal('show') }}> <i class="far fa-edit"></i> </button>;
  }




  const onSearch = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {

      let _lazyParams = { ...lazyParams, searchText: text };
      setLazyParams(_lazyParams);
    }, 475);

  }

  const deleteSchoolData = async () => {


    setDeleteSchoolDialog(false);
    deleteSchoolMuted(currentSchool.id)
  }

  const hideDeleteSchoolDialog = () => {

    setCurrentSchool({})
    setDeleteSchoolDialog(false);

  }

  const deleteSchoolDialogFooter = () => (
    <React.Fragment>
      <Button type="button" color="light" onClick={hideDeleteSchoolDialog} >No</Button>
      <Button type="button" color="primary" onClick={deleteSchoolData}>Yes</Button>
    </React.Fragment>
  );



  return (
    <React.Fragment>

      <Col lg={12}>
        <Card>
          <CardBody>
            <Row className='mr-0 mb-2'>
              <Col md={12} className="p-0">
                <div className="form-inline float-md-right">
                  <div className="search-box ml-2 mt-2">
                    <div className="position-relative">
                      <Input type="text" className="form-control-sm rounded" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} />
                      <i className="mdi mdi-magnify search-icon" style={{ lineHeight: "30px" }}></i>
                    </div>
                  </div>

                  <Button
                    color="success"
                    size="sm"
                    className="waves-effect waves-light ml-2 mt-2"
                    onClick={() => { history.push("/addschool") }}
                  >
                    <i className="ri-add-line"></i> Add School
                  </Button>
                </div>
              </Col>
            </Row>
            <DataTable
              lazy
              value={results?.results}
              paginator
              totalRecords={results?.count}
              responsiveLayout="scroll"
              stripedRows
              showGridlines

              size="small"
              loading={isLoading}
              onPage={onPage}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown small"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rows={lazyParams.rows}
              first={lazyParams.first}
              rowsPerPageOptions={[5, 10, 25]}
            >
              {/* <Column field="_id" header="Name"></Column> */}
              <Column field="id" header="Id"></Column>
              <Column field="name" body={(el) => el.get("name")} header="Name"></Column>
              <Column field="address" body={(el) => el.get("address")} header="Address"></Column>
              <Column headerStyle={{ width: '4rem' }} header="Action" body={actionBodyTemplate}></Column>


            </DataTable>
          </CardBody>
        </Card>
      </Col>


      <Dialog visible={deleteSchoolDialog}
        header="Confirm"
        modal
        footer={deleteSchoolDialogFooter}
        onHide={hideDeleteSchoolDialog}

      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-2 " style={{ fontSize: '2rem' }} />

          {
            Object.entries(currentSchool).length != 0 && <span>Are you sure you want to delete school and it's data - <b>{currentSchool.get("name")}</b> permanently ?</span>

          }

        </div>
      </Dialog>
    </React.Fragment>
  );
}

export default SchoolList; 