import React, { useEffect, useState } from 'react';
import {
  Col, Row, Input, Button, UncontrolledTooltip
} from "reactstrap";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { toast } from 'react-toastify';
import { getSchools } from '../../services/schools';

import { Link } from "react-router-dom";
import Switch from "react-switch";
import UserAddEdit from './UserAddEdit';
import { deleteUser, getUser } from '../../services/userMaster';

import { Dialog } from 'primereact/dialog';
import { getUserRole, isSuperAdmin } from '../../utils/utils';

const UserList = ({ schoolInfo = {} }) => {
  let typingTimeout = null;
  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [user, setUser] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isSuperAdminUser, setIsSuperAdminUser] = useState(isSuperAdmin());

  const [lazyParams, setLazyParams] = useState({
    searchText: "",
    first: 0,
    rows: 5,
    page: 1,
    sortField: "",
    sortOrder: 0,
    schoolId: schoolInfo.objectId
  });

  // React=query clinet to refeatch data, etc.. oprations
  const queryClient = useQueryClient()

  //get Code Queries
  const { data: results, refetch: reload, isLoading } = useQuery(['users', lazyParams], () => getUser(lazyParams), {
    keepPreviousData: true,
    onError: (error) => {
      toast.error(error.message);

    },
  })

  // Mutations to delete product
  const { mutateAsync: deleteMuted, isLoading: isDeleting } = useMutation(deleteUser, {

    onSuccess: (data) => {
      const previousValue = queryClient.getQueryData('users', { exact: false });
      const updatedValue = [...previousValue.results];
      const removeDeleted = updatedValue.filter(
        eachValue => eachValue.id !== data.id);
      previousValue.results = removeDeleted
      queryClient.setQueryData("users", previousValue);
      toast.success("User removed successfully")
      // queryClient.invalidateQueries('users')

    },
    onError: (error) => {
      toast.error(error.message);

    }
  })


  const onPage = (event) => {
    console.log("event", event)
    let data = event
    data.page = event.page + 1
    let _lazyParams = { ...lazyParams, ...data };
    setLazyParams(_lazyParams);
  }


  const onSort = (event, htt) => {

    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);


  }


  const editUser = (rowData) => {

    setUser(rowData)
    setIsEdit(true)
  }


  const onSearch = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {

      let _lazyParams = { ...lazyParams, searchText: text };
      setLazyParams(_lazyParams);
    }, 475);

  }


  const userTypeTemplate = (rowData) => {
    return <span >{getUserRole(rowData.get("userType"))}</span>;
  }


  const hideDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  }


  const deleteUserData = async () => {

    setDeleteUserDialog(false);

    await deleteMuted(user)

  }

  const confirmDeleteUser = (user) => {
    setUser(user);
    setDeleteUserDialog(true);
  }

  const actionBodyTemplate = (rowData) => {
    return <React.Fragment>
      <div className="d-flex">

        <span onClick={() => editUser(rowData)} className="mr-3 text-primary" style={{ cursor: "pointer" }} id="edit1"><i className="ri-pencil-line font-size-18"></i></span>
        <UncontrolledTooltip placement="auto" target="edit1">
          Edit
        </UncontrolledTooltip >

        <span onClick={() => confirmDeleteUser(rowData)} to="#" className="text-danger" id="delete1" style={{ cursor: "pointer" }}><i className="ri-delete-bin-6-line font-size-18"></i></span>
        <UncontrolledTooltip placement="auto" target="delete1">
          Delete
        </UncontrolledTooltip >


      </div>
    </React.Fragment>
    // <button className="btn-outline-primary btn-rounded waves-effect add-btn float-right ml-auto float-right"
    // onClick={() => { window.$('#Create').modal('show') }}> <i class="far fa-edit"></i> </button>;
  }

  const deleteUserDialogFooter = () => (
    <React.Fragment>
      <Button type="button" color="light" onClick={hideDeleteUserDialog} >No</Button>
      <Button type="button" color="primary" onClick={deleteUserData}>Yes</Button>
    </React.Fragment>
  );


  return (
    <React.Fragment>
      <Row className='mr-0 mb-2 mt-4'>
        <Col md="8">



          <div className="search-box mb-2 d-flex justify-content-end" >
            <div className="position-relative">
              <Input type="text" className="form-control-sm rounded" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} />
              <i className="mdi mdi-magnify search-icon" style={{ lineHeight: "30px" }}></i>
            </div>
          </div>







          <DataTable
            lazy
            value={results?.results}
            paginator
            totalRecords={results?.count}
            responsiveLayout="scroll"
            stripedRows
            showGridlines
            size="small"
            loading={isLoading}
            onPage={onPage}
            onSort={onSort}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown small"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={lazyParams.rows}
            first={lazyParams.first}
            rowsPerPageOptions={[5, 10, 25]}
            dataKey="id"
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            removableSort
          >
            {/* <Column field="_id" header="Name"></Column> */}
            {/* <Column field="name" header="Name"></Column> */}
            <Column field="name" body={(el) => el.get("name")} sortable header="Name"></Column>
            <Column field="username" body={(el) => el.get("username")} sortable header="Username"></Column>
            <Column field="type" body={userTypeTemplate} header="UserType"></Column>
            <Column headerStyle={{ width: '4rem' }} header="Action" body={actionBodyTemplate}></Column>


          </DataTable>

        </Col>
        <Col md="4" className='border-left'>
          <h4 className="card-title">{isEdit ? "Edit" : "Add"} User Information</h4>
          <hr className='m-0' />
          <UserAddEdit user={isEdit && user} isEdit={isEdit} schoolInfo={schoolInfo} callBack={(data) => { setIsEdit(data); setUser({}) }} />
        </Col>
      </Row>



      <Dialog visible={deleteUserDialog} header="Confirm" modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-5" style={{ fontSize: '2rem' }} />

          {Object.entries(user).length !== 0 && <span> Are you sure you want to delete user - <b>{user.get("name")}</b> ?</span>}
        </div>
      </Dialog>
    </React.Fragment>
  );
}

export default UserList; 