import React, { useEffect, useState } from 'react';
import { Media, Col, Row, Input, Button, UncontrolledTooltip } from "reactstrap";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'

import { useHistory } from "react-router-dom";
import { exportZipData, getOrderStatus, isSuperAdmin } from '../../utils/utils';
import { Dialog } from 'primereact/dialog';
import NotificationsService from '../../components/Common/toaster';

import moment from 'moment'


import './order.css';
import Parse from 'parse'
import { changeSchoolOrderStatus, getOrderDetails, getOrders } from '../../services/orders';
import OrderedStudnetCardGrid from './component/orderedStudnetCardGrid';

import { Calendar } from 'primereact/calendar';

const OrderHistory = ({ schoolInfo, ...props }) => {
  let typingTimeout = null;

  const history = useHistory()

  const [dates2, setDates2] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [changeOrderStatus, setChangeOrderStatus] = useState(false);
  const [changeOrderStatusData, setChangeOrderStatusData] = useState();
  const [downloadOrderDetails, setDownloadOrderDetails] = useState([]);
  const [currentRowId, setCurrentRowId] = useState("-1");
  const [currentRowSchooId, setCurrentRowSchooId] = useState("-");
  const [lazyParams, setLazyParams] = useState({
    searchText: "",
    dates: [],
    first: 0,
    rows: 5,
    page: 1,
    sortField: "",
    sortOrder: 0,
    selectedClass: -1,
    isOrdered: -1,
  });

  // React=query clinet to refeatch data, etc.. oprations
  const queryClient = useQueryClient()

  //get Orders Queries
  const { data: results, refetch: reload, isLoading, isFetching } = useQuery(['Orders', lazyParams, schoolInfo?.objectId],
    () => getOrders(lazyParams, schoolInfo?.objectId), {
    keepPreviousData: true,
    onError: (error) => {
      NotificationsService.error(error.message);

    },
  })

  //get Order Student data to downalod & view details  Query
  const { data: downloadData, refetch, isFetching: downloadFetching } = useQuery(['DownloadOrder', downloadOrderDetails, schoolInfo?.objectId],
    () => getOrderDetails(downloadOrderDetails, currentRowSchooId.objectId), {
    enabled: false,
    keepPreviousData: true,

    onSuccess: (data) => {
      if (!confirmDialog) {
        let excelFilename = currentRowSchooId.name
        exportZipData(data, excelFilename ,excelFilename )
        let a = downloadOrderDetails.splice()
        setDownloadOrderDetails(a)
        setCurrentRowId("-1")
      }
    },

    onError: (error) => {
      NotificationsService.error(error.message);

    },
  })

  // Mutations to chaneOrderStatus student
  const { mutateAsync, isLoading: isOrderUpdating } = useMutation(changeSchoolOrderStatus, {

    onSuccess: (data) => {

      NotificationsService.success("Order status updated !!!!")
      setChangeOrderStatus(false)
      setChangeOrderStatusData()
    },
    onError: (error) => {
      NotificationsService.error(error.message);

    }
  })


  const onPage = (event) => {
    let data = event
    data.page = event.page + 1
    let _lazyParams = { ...lazyParams, ...data };
    setLazyParams(_lazyParams);
  }

  const actionBodyTemplate = (rowData) => {
    let data = JSON.parse(JSON.stringify(rowData))
    let school = JSON.parse(JSON.stringify(rowData.get("schoolId")))
    return <React.Fragment>
      <div className="text-center">


        <span onClick={() => confirmChangeOrderStatus(rowData)} className="text-primary" id="changeStatus"
          style={{ cursor: "pointer" }}><i className="ri-edit-box-line font-size-18"></i></span>
        <UncontrolledTooltip placement="auto" target="changeStatus">
          Change order status
        </UncontrolledTooltip >


        <span onClick={() => {
          setCurrentRowSchooId(school)
          setCurrentRowId(data.objectId)
          handleDownloadData(rowData)
        }} className="text-primary ml-2" id="downloadData"
          style={{ cursor: "pointer" }}>
          {currentRowId === data.objectId ? <i className="ri-loader-2-line font-size-18"></i> : <i className="ri-download-cloud-line font-size-18"></i>}

        </span>
        <UncontrolledTooltip placement="auto" target="downloadData">
          Download Data
        </UncontrolledTooltip >



      </div>
    </React.Fragment>

  }

  const orderSummaryCardTemplate = (rowData, { rowIndex }) => {


    let orderSummary = rowData.get("orderSummary")
    let students = rowData.get("students")
    let status = rowData.get("status")
    let school = JSON.parse(JSON.stringify(rowData.get("schoolId")))

    return (
      <div className="col-12">

        <Media className="">

          <Media body>
            {isSuperAdmin() && <label className='text-uppercase mb-0'>
              <i className="ri-building-4-line align-bottom mr-1"></i>
              {orderSummary.schoolName}
            </label>}
            <h5 className="font-size-15 mt-3">
              <i className="ri-shopping-cart-line align-bottom mr-1"></i>
              <i>{"Total Students in Order :" + " " + orderSummary.totalStudent} </i></h5>


            <ul className="list-inline product-review-link mb-0 font-size-11">
              {orderSummary?.details.map((el, index) => <li className="list-inline-item" key={"classes" + index}>
                <span className="badge badge-soft-dark mr-1 mb-2 bdg">{el.class} ({el.classTotal})</span>
              </li>)}



            </ul>

          </Media>
          <div className="text-center" style={{ display: "grid" }} >
            {/* <div class={`product-ribbon badge badge-dark mt-2`}>{className}</div> */}

            {/* <div class={`product-ribbon badge badge-${rowIndex % 2 == 0 ? "warning" : "success"} mt-2`}>{rowIndex % 2 == 0 ? "Pending" : "Completed"}</div> */}

            <span className={`badge badge-soft-${status == 0 ? "danger" : status == 1 ? "warning" : "success"} font-size-11 mt-2`}>{getOrderStatus(status)}</span>

            <button onClick={() => {

              setCurrentRowSchooId(school)
              setDownloadOrderDetails(students)
              setConfirmDialog(true)

            }}
              className="waves-effect waves-light mr-1 btn btn-primary btn-sm mt-2">View Student(s)</button >

          </div>
        </Media >
      </div >
    );
  }

  const confirmChangeOrderStatus = (data) => {
    setChangeOrderStatusData(data)
    setChangeOrderStatus(true);
  }

  const hideConfirmOrderDialog = () => {
    setConfirmDialog(false);
    setCurrentRowSchooId()
    let a = downloadOrderDetails.splice()
    setDownloadOrderDetails(a)
    queryClient.removeQueries("DownloadOrder")
  }

  const hideChangeOrderDialog = () => {

    let objectId = changeOrderStatusData.id
    let status = document.querySelector(".changeOrderstatus select option:checked").value;
    let updatedData = { objectId, status, updatedby: Parse.User.current().id }
    if (changeOrderStatusData.get("status") == status) {
      NotificationsService.error("Please change order status before save ! ! !")
    } else {
      mutateAsync(updatedData)
    }

  }

  const confirmOrderDialogFooter = () => (
    <React.Fragment>
      <Button type="button" color="primary" onClick={hideConfirmOrderDialog} >Done</Button>
    </React.Fragment>

  );

  const confirmChangeOrderStautsDialogFooter = () => (
    <React.Fragment>
      <Button type="button" color="light" onClick={() => {
        setChangeOrderStatus(false)
        setChangeOrderStatusData()
      }} >Cancel</Button>
      <Button type="button" color="primary" disabled={isOrderUpdating} onClick={hideChangeOrderDialog} >{isOrderUpdating ? "Please wait ..." : "Save"}</Button>
    </React.Fragment>

  );


  const onSearch = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {

      let _lazyParams = { ...lazyParams, searchText: text };
      setLazyParams(_lazyParams);
    }, 475);

  }

  const handleDownloadData = (rowData) => {

    let students = rowData.get("students")
    setDownloadOrderDetails(students)
  }

  useEffect(() => {
    if (downloadOrderDetails.length !== 0) { refetch(); }

  }, [downloadOrderDetails])


  return (
    <React.Fragment>

      <Row className='mr-0 mb-2'>
        <Col md={4} style={{ textAlign: "left" }} className="d-flex">

          <div className="search-box ml-0 mt-2" style={{ width: "65%" }}>
            <div className="position-relative">
              <Input type="text" className="form-control-sm rounded"
                placeholder="Search by school name , orderId ..." onChange={(e) => onSearch(e.target.value)} />
              <i className="mdi mdi-magnify search-icon" style={{ lineHeight: "30px" }}></i>
            </div>
          </div>

        </Col>
        <Col md={8} style={{ justifyContent: "end" }} className="d-flex">
          <div >
            {/* <label htmlFor="range">Range  : </label> */}
            <Calendar id="range" value={dates2}
              dateFormat="d/m/yy"
              onChange={(e) => setDates2(e.value)}
              selectionMode="range"
              readOnlyInput
              placeholder='Please select date-range' />
            <Button
              onClick={() => {

                let isNullInDate = dates2.some(element => element === null)
                if (isNullInDate) {
                  NotificationsService.error("Please select range")
                  return
                }
                let _lazyParams = { ...lazyParams, dates: dates2 };
                setLazyParams(_lazyParams);
              }}
              color="primary"
              size="sm"
              className="waves-effect waves-light ml-2 mt-2"
            >
              <i className="ri-search-line align-bottom mr-1"></i> Search by date
            </Button>

            <Button
              onClick={() => {
                let _lazyParams = { ...lazyParams, dates: [] };
                setLazyParams(_lazyParams);
                setDates2([])
              }}
              color="light"
              size="sm"
              className="waves-effect waves-light ml-2 mt-2"
            >
              <i className="mdi mdi-filter-remove align-bottom mr-1"></i> Clear
            </Button>
          </div>
        </Col>
      </Row>

      <DataTable
        lazy
        value={results?.results}
        paginator
        totalRecords={results?.count}
        responsiveLayout="scroll"
        stripedRows
        // showGridlines
        size="small"
        loading={isFetching}
        onPage={onPage}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown small"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        rows={lazyParams.rows}
        first={lazyParams.first}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorPosition={'both'}
        dataKey="id"
        style={{ "border": "1px solid #E9ECEF" }}
        emptyMessage="No record found."
      >

        <Column field='id' headerStyle={{ width: '5rem' }} header="OrderId" style={{ "borderRight": "1px solid #E9ECEF" }}></Column>
        <Column field='createdAt' headerStyle={{ width: '7rem' }} body={(el) => moment.utc(el?.get("createdAt")).local().format("lll")} header="Order date"></Column>
        <Column body={orderSummaryCardTemplate} header="Order Summary" style={{ "borderLeft": "1px solid #E9ECEF" }}></Column>
        {isSuperAdmin() && <Column body={actionBodyTemplate} headerStyle={{ width: '5rem' }} style={{ "borderLeft": "1px solid #E9ECEF" }} header="Change Status"></Column>}
      </DataTable>

      <Dialog visible={confirmDialog}
        header="Students Details"
        modal
        footer={confirmOrderDialogFooter}
        onHide={hideConfirmOrderDialog}
        closable={false}
        style={{ height: "700px", width: "900px" }}
        position="bottom-right"
      >
        <div className="confirmation-content">
          <OrderedStudnetCardGrid value={downloadData} loading={downloadFetching} />
        </div>
      </Dialog>

      <Dialog visible={changeOrderStatus}
        header="Update Order Status"
        modal
        footer={confirmChangeOrderStautsDialogFooter}
        closable={false}
      >
        <hr className='m-0' />
        {changeOrderStatus && <div className="confirmation-content">

          <span className='changeOrderstatus'>

            <b><i>Current Status : </i> </b> <span className={`badge badge-soft-${changeOrderStatusData.get("status") == 0 ? "danger" : changeOrderStatusData.get("status") == 1 ? "warning" : "success"} font-size-11 mt-2`}>{getOrderStatus(changeOrderStatusData.get("status"))}</span>
            <br></br>
            <br></br>
            Chage Status to :  <select id="changeOrderStatus"
              className="custom-select custom-select-sm  ml-2 mt-2"
              style={{ width: "75%" }}
            >
              <option selected={changeOrderStatusData.get("status") == 0} value={0}>{getOrderStatus(0)}</option>
              <option selected={changeOrderStatusData.get("status") == 1} value={1}>{getOrderStatus(1)}</option>
              <option selected={changeOrderStatusData.get("status") == 2} value={2}>{getOrderStatus(2)}</option>

            </select>

          </span>

        </div>}
      </Dialog>


    </React.Fragment>
  );
}

export default OrderHistory; 