import React, { useEffect, useState, } from 'react';
import { Col, Row, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Input, Button, FormGroup, Label, ButtonGroup } from "reactstrap";

import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import moment from 'moment'
import NotificationsService from '../../components/Common/toaster';
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { editUser, saveUser } from '../../services/userMaster';
import { useParams } from 'react-router-dom';
import { getSchoolById } from '../../services/schools';
import { isSuperAdmin } from '../../utils/utils';



const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
// const schema = yup.object({
//   username: isSuperAdmin() ? yup.string().required('This field is required.').email("Email is not valid") : yup.string().required('This field is required.').matches(phoneRegExp, 'Phone number is not valid').max(10),
//   name: yup.string().required('This field is required.'),
//   // company: yup.string().required('This field is required.'),
//   // email: yup.string().email('Please enter valid email').required('This field is required.'),

// }).required();


const UserAddEdit = ({ schoolInfo, user = {}, isEdit = false, callBack, ...props }) => {


  const { id } = useParams()
  const [radioValue, setRadioValue] = useState(3)
  const [isSuperAdminUser, setIsSuperAdminUser] = useState(Object.keys(schoolInfo).length == 0 ? true : false);


  // HookForm Initialization
  const { register, handleSubmit, formState: { errors }, reset, setValue, control } = useForm({
    defaultValues: {},
    resolver: yupResolver(yup.object({
      username: isSuperAdminUser ? yup.string().required('This field is required.').email("Email is not valid") : yup.string().required('This field is required.').matches(phoneRegExp, 'Phone number is not valid').max(10),
      name: yup.string().required('This field is required.'),
      // company: yup.string().required('This field is required.'),
      // email: yup.string().email('Please enter valid email').required('This field is required.'),

    }).required())
  });


  // React=query clinet to refeatch data, etc.. oprations
  const queryClient = useQueryClient()

  // Mutations to add edit user
  const { mutateAsync } = useMutation(isEdit ? editUser : saveUser, {

    onSuccess: async (data, variables) => {
      // Invalidate and refetch
      console.log("data", data)
      console.log("variables", variables)
      isEdit ? queryClient.setQueryData(['users', { id: variables.objectId }], data) : queryClient.invalidateQueries('users')
      NotificationsService.success(isEdit ? "User update successfully" : "User added successfully")
      handleCancel()

    },
    onError: (error) => {
      NotificationsService.error(error.message);
    }
  })

  useEffect(() => {

    if (!isEdit) {
      restForm();
    }

    if (Object.entries(user).length !== 0) {

      setValue('name', user.get('name'))
      setValue('username', user.get('username'))
      setRadioValue(user.get('userType'))

    }

  }, [user])



  const restForm = () => {
    reset({ name: "", username: "", password: "" })
    setRadioValue(3)
  }


  const postUserData = async (data) => {

    let schoolLinkData = isSuperAdminUser ? null : await getSchoolById(schoolInfo?.objectId)
    let updatedData = { schoolid: schoolLinkData, type: parseInt(radioValue), ...data }
    console.log("updatedData old", updatedData)
    if (!updatedData.password) {
      if (!isEdit) updatedData.password = "1234"

    }
    if (isEdit) {
      if (!updatedData.password) delete updatedData.password
      delete updatedData.schoolid
      updatedData.objectId = user.id
    }

    console.log("data", data)
    console.log("updatedData", updatedData)
    console.log("schoolid", id)
    mutateAsync(updatedData);
  }
  const handleCancel = () => {
    restForm()
    callBack(false)
  }

  return (
    <React.Fragment>
      <FormGroup className='mt-2'>
        <Label className="col-md-12 col-form-label  pl-0">User Type</Label>
        <div className='d-flex'>
          <div className="custom-control custom-radio mt-1" key={"radio"}>
            <Input type="radio" id={"customRadio1"} name="customRadio" className="custom-control-input"
              onChange={(e) => { setRadioValue(e.target.value) }}
              value={isSuperAdminUser ? 1 : 3} checked={radioValue == (isSuperAdminUser ? 1 : 3)} />
            <Label className="custom-control-label" htmlFor={"customRadio1"} style={{ textTransform: "capitalize" }}>
              {isSuperAdminUser ? "Superadmin" : "School admin"}
            </Label>
          </div>

          <div className="custom-control custom-radio mt-1 ml-2" key={"radio2"}>
            <Input type="radio" id={"customRadio2"} name="customRadio" className="custom-control-input"
              value={isSuperAdminUser ? 2 : 4} checked={radioValue == (isSuperAdminUser ? 2 : 4)} onChange={(e) => { setRadioValue(e.target.value) }} />
            <Label className="custom-control-label" htmlFor={"customRadio2"} style={{ textTransform: "capitalize" }}>
              {isSuperAdminUser ? "Operator" : " School staff"}

            </Label>
          </div>
        </div>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="name" className="col-md-12 col-form-label  pl-0">Full Name</Label>
        <Controller
          name="name"
          control={control}
          render={({ field }) => <Input className="form-control" type="text" id="name" {...field} placeholder="Full Name" />}
        />
        {<div className="error">{errors.name?.message}</div>}
      </FormGroup>
      <FormGroup>
        <Label htmlFor="name" className="col-md-12 col-form-label  pl-0">{isSuperAdminUser ? "Phone number / Email" : "Phone number"}</Label>
        <Controller
          name="username"
          control={control}
          render={({ field }) => <Input className="form-control" type={isSuperAdminUser ? "text" : "number"} id="username" {...field} placeholder="User Name" />}
        />
        {<div className="error">{errors.username?.message}</div>}


      </FormGroup>
      <FormGroup>
        <Controller
          name="password"
          control={control}
          render={({ field }) => <Input className="form-control" type="password" id="password" {...field} placeholder="Password" />}
        />

        <span className='card-title-desc'>leave it blank {!isEdit ? "to set defualt password to 1234" : "if you dont want to reset"}</span>

      </FormGroup>

      <FormGroup >
        <Button type='button' color="success" className="waves-effect waves-light ml-1"
          onClick={handleSubmit(data => postUserData(data))}
        >
          {isEdit ? "Update" : "Save"} <i className="ri-save-line align-bottom ml-1" ></i>
        </Button>
        <Button color="danger" className="waves-effect waves-light ml-1" onClick={handleCancel}>
          cancel <i className="ri-close-circle-line align-bottom ml-1"></i>
        </Button>

      </FormGroup>


    </React.Fragment>
  );
}

export default UserAddEdit; 