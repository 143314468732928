import React, { Component, useState, useEffect } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, CardBody, Card } from "reactstrap";
import classnames from 'classnames';



//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import StudentList from '../StudentList/StudentList';
import UserList from '../User/UserList';
import AddSchoolForm from '../SchoolAdd/SchoolAddEdit';
import { Link, useHistory, useParams } from "react-router-dom";
import { getSchoolById } from '../../services/schools';
import { storage, getSchoolInfo } from '../../utils/utils';



const StudentDetails = ({ title, ...props }) => {




    const [schoolInfo, setSchoolInfo] = useState()
    const [activeTab, setActiveTab] = useState("1")
    const [breadcrumbItems] = useState([
        // { title: "Students", link: "/schools" },
        // { title: "Dashboard", link: "#" },
    ]);



    useEffect(() => {

    }, [breadcrumbItems, title]);

    useEffect(() => {


        let path = props.match.path.substr(1,)
        switch (path) {
            case "dashboard":
                toggleTab(1)
                break;
            case "users":
                toggleTab(2)
                break;
            case "setting":
                toggleTab(3)
                break;

            default:
                break;
        }
    }, [props.pageTitle]);

    useEffect(() => {

        let schoolData = storage.getSchool()
        console.log("schoolData", schoolData)
        setSchoolInfo(schoolData)
        // getSchoolById(id).then((res) => {
        //     console.log("res", res[0])
        //     setSchoolInfo(res[0])
        //     storage.setSchool(res[0])
        //     globalStore.setState("currentSchool", (res[0]));

        // })

    }, [])

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.pageTitle} breadcrumbItems={breadcrumbItems} />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody >
                                    {console.log("schoolInfo", schoolInfo)}
                                    {activeTab == 1 && schoolInfo && < StudentList schoolInfo={schoolInfo} />}
                                    {activeTab == 2 && schoolInfo && < UserList schoolInfo={schoolInfo} />}
                                    {activeTab == 3 && schoolInfo && <AddSchoolForm schoolInfo={schoolInfo} />}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
}

export default StudentDetails;