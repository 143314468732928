import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes, authProtectedSuperAdminRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

import { queryClient } from './reactQuery';
import { ReactQueryDevtools } from 'react-query/devtools'
import {
	QueryClientProvider,
} from 'react-query'

// Import scss
import "./theme.scss";

//Fake backend
import fakeBackend from './helpers/AuthType/fakeBackend';

//Import Prime react
import "primereact/resources/themes/lara-light-indigo/theme.css"    //theme
import "primereact/resources/primereact.min.css"                    //core css
import "primeicons/primeicons.css"                                  //icons
import Parse from 'parse';
import { isSuperAdmin } from "./utils/utils";

// //Firebase helper
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Activating fake backend
fakeBackend();

// const firebaseConfig = {
// 	apiKey: process.env.REACT_APP_APIKEY,
// 	authDomain: process.env.REACT_APP_AUTHDOMAIN,
// 	databaseURL: process.env.REACT_APP_DATABASEURL,
// 	projectId: process.env.REACT_APP_PROJECTID,
// 	storageBucket: process.env.REACT_APP_STORAGEBUCKET,
// 	messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
// 	appId: process.env.REACT_APP_APPID,
// 	measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// // init firebase backend
// initFirebaseBackend(firebaseConfig);

// globalStore.setState("currentUser", Parse.User.current());

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.getLayout = this.getLayout.bind(this);

	}

	/**
   * Returns the layout
   */
	getLayout = () => {
		let layoutCls = VerticalLayout;

		switch (this.props.layout.layoutType) {
			case "horizontal":
				layoutCls = HorizontalLayout;
				break;
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	};

	render() {
		const Layout = this.getLayout();

		return (
			<React.Fragment>

				<QueryClientProvider client={queryClient}>
					<Router>
						<Switch>
							{publicRoutes.map((route, idx) => (
								<AppRoute
									path={route.path}
									layout={NonAuthLayout}
									component={route.component}
									key={idx}
									isAuthProtected={false}
								/>
							))}

							{Parse.User.current() && isSuperAdmin() ? authProtectedSuperAdminRoutes.map((route, idx) => (
								<AppRoute
									path={route.path}
									layout={Layout}
									title={route.title}
									component={route.component}
									key={idx}
									isAuthProtected={true}
								/>
							))
								: authProtectedRoutes.map((route, idx) => (
									<AppRoute
										path={route.path}
										layout={Layout}
										title={route.title}
										component={route.component}
										key={idx}
										isAuthProtected={true}
									/>
								))
							}
						</Switch>
					</Router>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};


export default connect(mapStateToProps, null)(App);
