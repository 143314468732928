import React, { useState } from 'react';
import { Col, Input, Button, } from "reactstrap";
import { ListBox } from 'primereact/listbox';
import NotificationsService from './toaster';
import { deleteStudentClass, updateSchoolClass, updateStudentClass } from '../../services/schools';
import {
    useQuery,
    useMutation,
    useQueryClient,
} from 'react-query'


const EditableListBox = ({ schoolId = undefined, items, getData, isEdit = false, ...props }) => {

    const [itemParams, setItemParams] = useState({
        arrayItems: items,
        textBoxValue: "",
        selectedListBoxItem: "",
    });

    // Mutations to update student class befor updating classes
    const { mutateAsync, isLoading } = useMutation(updateStudentClass, {

        onSuccess: async (data, variables) => {
            NotificationsService.success("Class updated successfully")
            handleCancel()

        },
        onError: (error) => {
            NotificationsService.error(error.message);
            handleCancel();
        }
    })


    const updateClass = async () => {



        let _itemParams = { ...itemParams }

        if (_itemParams.arrayItems.includes(_itemParams.textBoxValue)) {
            NotificationsService.error("Item already exist")
            return
        }


        if (_itemParams.textBoxValue == "") {
            NotificationsService.error("Please enter item name")
            return

        }

        if (_itemParams.selectedListBoxItem != "") {

            let itemIndex = _itemParams.arrayItems.findIndex(item => item.toString() == _itemParams.selectedListBoxItem.toString())
            const newList = _itemParams.arrayItems.map((item, index) => {
                return index === itemIndex ? _itemParams.textBoxValue : item
            })

            if (isEdit) {
                let el = {
                    classToFind: _itemParams.selectedListBoxItem.toString(),
                    ClassToUpdate: _itemParams.textBoxValue,
                    schoolId: schoolId,
                    newList
                }
                let data = await mutateAsync(el)
                console.log("data", data)
            }
            _itemParams.arrayItems = newList


        } else {
            let newList = [_itemParams.textBoxValue, ..._itemParams.arrayItems]
            if (isEdit) {
                let el = {
                    schoolId: schoolId,
                    newList
                }
                console.log("data", el)
                let data = await mutateAsync(el)

            }
            _itemParams.arrayItems = newList


        }
        _itemParams.textBoxValue = ""
        _itemParams.selectedListBoxItem = ""
        getData(_itemParams.arrayItems)
        setItemParams(_itemParams)
    }

    const deleteClass = async () => {

        let _itemParams = { ...itemParams }

        if (_itemParams.selectedListBoxItem != "") {
            let itemIndex = _itemParams.arrayItems.findIndex(item => item.toString() === _itemParams.selectedListBoxItem.toString())
            const newList = _itemParams.arrayItems.filter((item, index) => {
                return index !== itemIndex
            })

            let el = {
                classToFind: _itemParams.selectedListBoxItem.toString(),
                schoolId: schoolId,
                newList
            }
            if (isEdit) {
                let data = await mutateAsync(el)
                console.log("data", data)
            }
            _itemParams.arrayItems = newList


        }
        _itemParams.textBoxValue = ""
        _itemParams.selectedListBoxItem = ""
        getData(_itemParams.arrayItems)
        setItemParams(_itemParams)


    }

    const handleCancel = () => {

        let _itemParams = { ...itemParams }
        _itemParams.textBoxValue = ""
        _itemParams.selectedListBoxItem = ""
        setItemParams(_itemParams)

    }

    const handleClassListBox = (value, inputType = 1) => {
        // inputType 1 = for ListboxControl, 2 = for textInputControl
        let _itemParams = { ...itemParams, ...(inputType == 1) && { selectedListBoxItem: value }, textBoxValue: value }

        setItemParams(_itemParams)

    }


    return (
        <React.Fragment>
            <Col md={12} className="d-flex">
                <Input className="form-control" type="text" id="example-text-input"
                    value={itemParams.textBoxValue}

                    onChange={(e) => { handleClassListBox(e.target.value, 2) }}
                />

                <Button color="success" disabled={isLoading} className='ml-2' onClick={updateClass}>
                    {!isLoading ? <i className="ri-add-circle-line align-middle"></i> : <i class="ri-loader-2-line"></i>}
                </Button>
                <Button color="danger" className='ml-2' onClick={deleteClass}>
                    {!isLoading ? <i className="ri-delete-bin-2-line align-middle"></i> : <i class="ri-loader-2-line"></i>}

                </Button>

                <Button color="info" className='ml-2' onClick={handleCancel}>
                    <i className="ri-close-line align-middle"></i>
                </Button>

            </Col>

            <ListBox
                value={itemParams.selectedListBoxItem}
                options={itemParams.arrayItems}
                onChange={(e) => { handleClassListBox(e.value, 1, 2) }}
                className="ml-3 mt-2"

                style={{ width: '15rem', fontFamily: "nunito" }} listStyle={{ maxHeight: '250px' }} />
        </React.Fragment>
    )

}

export default EditableListBox;
