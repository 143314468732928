import Parse from 'parse';
import moment from 'moment'



export const saveOrders = async (data) => {
    const { schoolId } = data

    const newEntry = new Parse.Object("Orders");



    Object.keys(data).map(key =>

        newEntry.set(key, data[key])
    )

    return newEntry.save()

}


export const updateStudentOrderStatus = async (StudentData, schoolId) => {

    var Student_Object = Parse.Object.extend("Students_" + schoolId);
    let data = StudentData
    var studentsToImport = [];
    data.map((item) => {
        let studentEntry = new Student_Object();
        studentEntry.set("objectId", item)
        studentEntry.set("isOrdered", true)
        studentsToImport.push(studentEntry);
    })

    console.log("studentsToImport", studentsToImport)

    // return Parse.Object.saveAll(studentsToImport);


}

export const getOrders = async (filters = {}, schoolId, fields = 'name,address') => {

    console.log("schoolId", schoolId)
    // schoolId = "BzpytMkBpf"

    let queryFilters = filters
    const { rows, page, first, searchText, sortField, sortOrder, dates = [] } = queryFilters
    const parseCode = new Parse.Object("Orders");

    console.log("queryFilters", filters)
    const queries = [];

    if (searchText) {
        queries.push(new Parse.Query(parseCode).matches('orderSummary.schoolName', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('objectId', searchText, "i"));

    }


    const mainQuery = searchText ? new Parse.Query.or(...queries) : new Parse.Query("Orders");
    mainQuery.skip(first)
    mainQuery.limit(rows)
    switch (sortOrder) {
        case -1:
            mainQuery.descending(sortField);
            break;
        case 1:
            mainQuery.ascending(sortField);
            break;
        default:
            mainQuery.ascending("studentname");
            break;
    }

    if (dates.length != 0) {
        var start = moment(dates[0]).startOf('day') //Start of day
        var end = moment(dates[1]).endOf('day') //End of day

        mainQuery.greaterThanOrEqualTo('createdAt', start.toDate());
        mainQuery.lessThanOrEqualTo('createdAt', end.toDate());

    }
    if (schoolId) {
        const obj = { "__type": "Pointer", className: "schools", "objectId": schoolId };
        mainQuery.equalTo("schoolId", obj)
    }
    mainQuery.withCount();


    mainQuery.descending("createdAt");
    mainQuery.include('schoolId');
    // mainQuery.select(fields);
    return mainQuery.find();
}


export const getOrderDetails = async (data, schoolId) => {
    console.log("data", data, schoolId)

    // const parseCode = new Parse.Object("Students_" + schoolId);
    const mainQuery = new Parse.Query("Students_" + schoolId);
    mainQuery.containedIn("objectId", data)
    // mainQuery.withCount();
    let abc = await mainQuery.findAll();

    return abc
}

export const changeSchoolOrderStatus = async (data) => {
    const { objectId, status, updatedBy } = data
    const updateEntry = new Parse.Object("Orders");
    updateEntry.set("objectId", objectId)
    updateEntry.set("status", parseInt(status))
    updateEntry.set("updatedBy", updatedBy)
    return updateEntry.save();
}