import React, { useEffect, useState } from 'react';
import { Col, Row, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Input, Button, FormGroup, Label, ButtonGroup } from "reactstrap";

import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'

import { Calendar } from 'primereact/calendar';
import moment from 'moment'

import EditableListBox from '../../components/Common/EditableListBox';
import NotificationsService from '../../components/Common/toaster';
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { saveSchool } from '../../services/schools';
import { useHistory } from 'react-router-dom';


const schema = yup.object({
  name: yup.string().required('This field is required.'),
  // company: yup.string().required('This field is required.'),
  // email: yup.string().email('Please enter valid email').required('This field is required.'),

}).required();


const AddSchoolForm = ({ schoolInfo }) => {

  const schoolClass = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
  const schoolDivison = ["A", "B", "C", "D"]
  const days = [
    {
      day: "monday", from: 1651198217115, to: 1651216337116

    },
    {
      day: "tuesday", from: 1651198217115, to: 1651216337116

    },
    {
      day: "wednesday", from: 1651198217115, to: 1651216337116

    },
    {
      day: "thursday", from: 1651198217115, to: 1651216337116

    },
    {
      day: "friday", from: 1651198217115, to: 1651216337116

    },
    {
      day: "saturday", from: 1651198217115, to: 1651216337116

    },
    {
      day: "sunday", from: 1651198217115, to: 1651216337116

    },
  ]

  const [toDate, setTodate] = useState(null);
  const [fromDate, setFromdate] = useState(null);
  const [shiftData, setShiftData] = useState(schoolInfo ? schoolInfo?.shifttime : days);

  const [classes, setClasses] = useState(schoolInfo ? schoolInfo?.class : schoolClass);
  const [divisions, setDivisions] = useState([...schoolDivison]);
  const [isEdit, setIsEdit] = useState(schoolInfo ? true : false);
  const history = useHistory()

  // HookForm Initialization
  const { register, handleSubmit, formState: { errors }, reset, setValue, control, resetFormValues } = useForm({
    defaultValues: {
      name: schoolInfo ? schoolInfo?.name : "",
      address: schoolInfo ? schoolInfo?.address : "",
      desc: schoolInfo ? schoolInfo?.description : ""
    },
    resolver: yupResolver(schema)
  });


  useEffect(() => {
    // console.log("location.pathname", schoolInfo); // result: '/secondpage'
    // reset({ name: schoolInfo.get("name"), address: schoolInfo.get("address"), desc: schoolInfo.get("description") })




  }, []);

  const saveSchooltime = () => {
    let currentRadioSelection = document.querySelector('input[name="customRadio"]:checked').value;

    let fromTime = moment(fromDate, "HH:MM").valueOf();
    let toTime = moment(toDate, "HH:MM").valueOf();
    if (fromTime >= toTime) {
      NotificationsService.error("Please enter valid time")
      return
    }
    let newData = shiftData.map(el => (currentRadioSelection == "all" ? ({ ...el, from: fromTime, to: toTime }) : el.day === currentRadioSelection ? { ...el, from: fromTime, to: toTime } : el))
    console.log("first", newData)
    setShiftData(newData)
    NotificationsService.success("Time set successfully")


  }

  const submitSchoolData = (data, e) => {


    let updatedData = { shift: shiftData, ...data }
    if (isEdit) {
      updatedData = { isEdit, objectId: schoolInfo.objectId, ...updatedData }
    } else {
      updatedData = { classes, ...updatedData }
    }
    console.log("updateddata", updatedData)

    saveSchool(updatedData).then(
      NotificationsService.success(isEdit ? "School information successfully updated  " : "Wow, School created !!! ")
    ).catch((err) => {
      NotificationsService.error(err.message)
    })

    console.log("first", schoolClass)
    if (!isEdit) {
      let cloneclass = schoolClass.splice();
      setClasses(cloneclass)
      setDivisions(schoolDivison)
      setShiftData(days)
      restForm()
    }

  }

  const restForm = () => {
    reset({ name: "", address: "", desc: "" })
  }


  return (
    <React.Fragment>

      <Col lg={12}>
        <Card>
          <CardBody>
            <Row className='mr-0 mb-2'>
              <Col md={4} className="">

                <h4 className="card-title">School Details</h4>
                <hr className='m-0' />
                <FormGroup>
                  <Label htmlFor="name" className="col-md-12 col-form-label">SchoolName</Label>
                  <Col md={12}>

                    <Controller
                      name="name"
                      control={control}

                      render={({ field }) => <Input className="form-control" type="text" id="name" {...field} />}
                    />
                    {<div className="error">{errors.name?.message}</div>}

                  </Col>
                </FormGroup>


                <FormGroup>
                  <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Address</Label>
                  <Col md={12}>
                    <Controller
                      name="address"
                      control={control}

                      render={({ field }) => <Input className="form-control" type="textarea" id="address" {...field} />}
                    />

                  </Col>
                </FormGroup>


                <FormGroup>
                  <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Description</Label>
                  <Col md={12}>
                    <Controller
                      name="desc"
                      control={control}

                      render={({ field }) => <Input className="form-control" type="desc" id="desc" {...field} />}
                    />
                  </Col>
                </FormGroup>




              </Col>
              <Col md={4} className="">
                <div className="flex justify-content-center">
                  <h4 className="card-title">Shift Details</h4>
                  <hr className='m-0' />

                  <Col md={12} >
                    {

                      shiftData.map((item, index) =>
                        <div className="custom-control custom-radio mt-1" key={"radio" + index}>
                          <Input type="radio" id={"customRadio1" + index} name="customRadio" className="custom-control-input" value={item.day} />
                          <Label className="custom-control-label" htmlFor={"customRadio1" + index} style={{ textTransform: "capitalize" }}>
                            {item.day} <br />
                            (<span className='card-title-desc'>From : {moment(item.from).format("hh:mm a")} - To: {moment(item.to).format("hh:mm a")}</span>)</Label>
                        </div>

                      )
                    }
                    <div className="custom-control custom-radio mt-2">
                      <Input type="radio" id={"all"} name="customRadio" className="custom-control-input" value={"all"} defaultChecked />
                      <Label className="custom-control-label" htmlFor={"all"} style={{ textTransform: "capitalize" }}>Set all days time  </Label>
                    </div>
                  </Col>
                  <FormGroup className='mt-2'>
                    <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Set Time</Label>
                    <Col md={8} className="d-flex">
                      <Calendar className='ml-2' id="time12" value={fromDate} onChange={(e) => setFromdate(e.value)} timeOnly hourFormat="12" placeholder='From' />
                      <Calendar className='ml-2' id="time12" value={toDate} onChange={(e) => setTodate(e.value)} timeOnly hourFormat="12" placeholder='To' />

                    </Col>
                  </FormGroup>

                  <FormGroup className='mt-2' style={{ textAlign: 'end' }}>
                    <Button color="info" className="waves-effect waves-light ml-1"
                      onClick={saveSchooltime} disabled={(fromDate && toDate ? false : true)} >
                      Save Time
                      <i className="ri-time-line align-bottom  ml-2"></i>
                    </Button>
                  </FormGroup>



                </div>

              </Col>
              <Col md={4} className="">
                <h4 className="card-title">Classes Details</h4>
                <hr className='m-0' />
                <Row>
                  <Col md={12} >
                    <FormGroup>
                      <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Class</Label>
                      <EditableListBox items={classes} getData={(data) => { setClasses(data) }} isEdit={isEdit} schoolId={schoolInfo?.objectId} />

                    </FormGroup>
                  </Col>
                  {/* <Col md={6} >
                    <FormGroup>
                      <Label htmlFor="example-text-input" className="col-md-6 col-form-label">Division</Label>

                      <EditableListBox items={divisions} getData={(data) => { setDivisions(data) }} />


                    </FormGroup>

                  </Col> */}

                </Row>
              </Col>
            </Row>

            <Row className='pr-3'>
              <Col md={8}>
                <hr />
                <FormGroup >
                  <Button color="success" className="waves-effect waves-light ml-1"

                    onClickCapture={handleSubmit((data, e) => submitSchoolData(data, e))} >
                    Save <i className="ri-save-line align-bottom ml-1"></i>
                  </Button>
                  <Button color="danger" className="waves-effect waves-light ml-1" onClick={() => { restForm(); history.push('/schools') }} >
                    cancel <i className="ri-close-circle-line align-bottom ml-1"></i>
                  </Button>

                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

      </Col>

    </React.Fragment>
  );
}

export default AddSchoolForm; 