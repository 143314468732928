import { parse } from 'dotenv';
import Parse from 'parse';



export const saveUser = (data) => {
  const { username, name, password, type, schoolid } = data
  console.log("Product data", data)

  // let newUser = new Parse.Object('User');
  let user = new Parse.User();
  user.setUsername(username);
  // user.setEmail(username);
  user.setPassword(password) 
  user.set("name", name)
  user.set("userType", type)
  user.set("schoolId", schoolid == null ? [] : [schoolid])
  // user.set("assigneCustomer",[])
  // user.set("parentId ",null)
  // user.set("clusters",{"name":"cluster"})

  // user.save({ userMasterKey: true });

  // newUser.set('username', name);
  // newUser.set('email', name);

  // newProduct.set('name', name);
  // newProduct.set("deviceId", devices);
  // newProduct.add("hhhh", devices);
  // newProduct.add("codeId", alarms);

  // Code to save relation ***h
  // let deviceRelation = newProduct.relation('deviceId')
  // deviceRelation.add(devices);
  // let alarmRelation = newProduct.relation('AlarmId')
  // alarmRelation.add(alarms);
  //*******/

  return user.save()
}

export const editUser = async (data) => {

  // let updateobj = new Parse.Object.extend('User');
  // let updatedUser = new Parse.User();
  let result = await Parse.Cloud.run("updateUser", data)

  return result
  // console.log("Result" , result)
  // Parse.Cloud.run("updateUser", updata).then((res) => {
  //   console.log("res", res)
  // }).catch((err) => {
  //   console.log("err", err)
  // })


  // const { email, company, name, phone } = data
  // updateobj.set('username', email);
  // updateobj.set('name', name);
  // updateobj.set('phone', phone);
  // updateobj.set('companyname', company);

  // updateobj.remove('deviceId')
  // updateobj.add('deviceId', devices);

  // Code to save relation ***
  // let deviceRelation = updateobj.relation('hhh')
  // deviceRelation.add(devices);
  // return updateobj.save()
}


export const updatePassword = async (data) => {


  let updatedUser = new Parse.User();
  const currentUser = JSON.parse(JSON.stringify(Parse.User.current()))
  updatedUser.set("objectId", currentUser.objectId)
  updatedUser.setPassword(data)
  return updatedUser.save()

}

export const deleteUser = async (deleteObj) => {

  let updatedData = { objectId: deleteObj.id }
  return Parse.Cloud.run("deleteUser", updatedData)

}

export const getUser = async (filters = {}, fields = 'name,desc') => {
  const parseCode = new Parse.Object('_User');
  let queryFilters = filters
  const { rows, page, first, searchText, sortField, sortOrder, schoolId } = queryFilters
  console.log("get query schoolId", schoolId)
  const queries = [];

  if (searchText) {
    queries.push(new Parse.Query(parseCode).matches('name', searchText, "i"));
    queries.push(new Parse.Query(parseCode).matches('username', searchText, "i"));
  }


  const mainQuery = searchText ? new Parse.Query.or(...queries) : new Parse.Query("_User");
  mainQuery.skip(first)
  mainQuery.limit(rows)
  switch (sortOrder) {
    case -1:
      mainQuery.descending(sortField);
      break;
    case 1:
      mainQuery.ascending(sortField);
      break;
    default:
      mainQuery.descending("createdAt");
      break;
  }
  mainQuery.equalTo("schoolId.objectId", schoolId)
  mainQuery.include('schoolId');
  mainQuery.withCount();


  return mainQuery.find()
}

