import React, { useEffect, useState } from 'react';
import { Card, CardBody, FormGroup, Label, Col, Row, Input, Button, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { toast } from 'react-toastify';

import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import { storage } from '../../utils/utils';
import { getStudents, } from '../../services/students';
import { Calendar } from 'primereact/calendar';
import NotificationsService from '../../components/Common/toaster';
import { getAttedanceReport, getStudentsAttedanceData } from '../../services/reports';
import moment from 'moment'
import { Toolbar } from 'primereact/toolbar';
import { map } from 'lodash';
import './dt.css';

const AttendanceMonthly = ({ schoolInfo, ...props }) => {
  let typingTimeout = null;

  const history = useHistory()

  const [dates2, setDates2] = useState(new Date());
  const [daysInMonth, setDaysInMonth] = useState(moment().daysInMonth());


  const [lazyParams, setLazyParams] = useState({
    searchText: "",
    date: [],
    isPresent: "-1",
    isMonthView: true,
    first: 0,
    rows: 5,
    page: 1,
    sortField: "",
    sortOrder: 0,
    selectedClass: schoolInfo ? schoolInfo.class[0] : -1
  });

  // React=query clinet to refeatch data, etc.. oprations
  const queryClient = useQueryClient()

  //get Student Queries
  const { data: results, refetch: reload, isLoading, isFetching } =
    useQuery(['studentsReports', lazyParams, schoolInfo.objectId],
      () => getStudentsAttedanceData(lazyParams, schoolInfo.objectId), {
      keepPreviousData: true,
      // staleTime: 10000, // only eligible to refetch after 10 seconds
      onError: (error) => {
        toast.error(error.message);

      },
    })


  const onPage = (event) => {
    let data = event
    data.page = event.page + 1
    let _lazyParams = { ...lazyParams, ...data };
    setLazyParams(_lazyParams);
  }

  const onSearch = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {

      let _lazyParams = { ...lazyParams, searchText: text };
      setLazyParams(_lazyParams);
    }, 475);

  }

  const onClassChange = (text) => {

    let _lazyParams = { ...lazyParams, selectedClass: text };
    setLazyParams(_lazyParams);


  }


  const imageBodyTemplate = (rowData, { rowIndex }) => {

    let noPhotoUrl = "'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'"
    return <div className='text-center' key={"imgDiv" + rowIndex}>
      <img
        src={rowData.get("photo") ? rowData.get("photo")?._url : noPhotoUrl}
        onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
        alt={"Sorry"}
        width={50}
        height={50}
        className="product-image mb-1" />

    </div>
  }

  const fullNameTemplate = (rowData) => {
    let fName = rowData.get("studentname") ? rowData.get("studentname") : ""
    let mName = rowData.get("fathername") ? rowData.get("fathername") : "";
    let lName = rowData.get("surname") ? rowData.get("surname") : ""
    return <React.Fragment>
      <div>
        {fName + " " + mName + " " + lName}
      </div>
    </React.Fragment>
  }



  const attendanceStatusTemplate = (rowData, colData) => {
    // console.log("col data", colData)
    // console.log("rowData data", rowData.attendancedata ? rowData.attendancedata : "---")
    let isAbsent = "---"
    if (rowData.attendancedata) {
      rowData.attendancedata?.map((el, idx) => {
        let dateOfAttedance = moment(el.createdAt).format("D")
        let currColDate = colData == 5 ? colData + 23 : colData
        console.log("first", dateOfAttedance)
        console.log("colData", colData)
        console.log("is true", dateOfAttedance == colData)
        if (dateOfAttedance == colData) {
          isAbsent = !el.attendance
        }
      })
    }
    return <React.Fragment>
      <div className='textAlign-center'>
        {/* <span className={`badge badge-soft-${isAbsent ? "danger" : "success"} mr-1 mb-2 bdg`}>{isAbsent === true ? "A" : isAbsent == "---" ? isAbsent : "P"}</span> */}
        <span className={`badge badge-soft-${isAbsent ? "danger" : "success"} mr-1 mb-2 bdg`}>{isAbsent ? isAbsent == "---" ? isAbsent : "A" : "P "}</span>
      </div>
    </React.Fragment>
  }



  const leftContents = (
    <React.Fragment>
      <FormGroup>

        <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Attendance Status</Label>
        <Col md={12}>
          {<select className="custom-select custom-select-sm"
            onChange={(e) => {
              let _lazyParams = { ...lazyParams, isPresent: e.target.value }
              setLazyParams(_lazyParams);
            }}
          >
            <option defaultChecked value={"-1"}>All</option>
            <option defaultChecked value={true}>Present</option>
            <option defaultChecked value={false}>Absent</option>

          </select>
          }
        </Col>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="example-text-input" className="col-md-12 col-form-label pl-0">Select Class</Label>
        {<select className="custom-select custom-select-sm "
          onChange={(e) => { onClassChange(e.target.value) }}>
          {schoolInfo.class.map(item => <option key={item} value={item}>{item}</option>)}
        </select>
        }
      </FormGroup>
    </React.Fragment>
  );

  const rightContents = (
    <React.Fragment>
      <Row>

        <Col md={12} className="text-right" >

          <Calendar
            id="range"
            maxDate={new Date()}
            value={dates2}
            dateFormat="MM yy"
            onChange={(e) => {
              let days = moment(e.value).daysInMonth()
              // let isMonthView = false              
              // let start = moment(e.value).startOf(isMonthView ? 'month' : 'day').toDate()
              // let end = moment(e.value).endOf(isMonthView ? 'month' : 'day').toDate()
              // console.log("start", start)
              // console.log("end", end)
              setDaysInMonth(days)
              setDates2(e.value)
            }}
            readOnlyInput
            view="month"
            inputStyle={{ fontStyle: "oblique", fontWeight: "bold" }}
            placeholder='Please select date-range' />
        </Col>
        <Col md={12} className="text-right" >
          <Button
            onClick={() => {
              console.log("dates2", dates2)
              let _lazyParams = { ...lazyParams, date: dates2 };
              setLazyParams(_lazyParams);
            }}
            color="primary"
            size="sm"
            disabled={isFetching}
            className="waves-effect waves-light ml-2 mt-2"
          >
            <i className="ri-search-line align-bottom mr-1"></i> Search
          </Button>

          <Button
            onClick={() => {
              let _lazyParams = { ...lazyParams, date: [] };
              setLazyParams(_lazyParams);
              setDates2( new Date())
            }}
            color="light"
            size="sm"
            className="waves-effect waves-light ml-2 mt-2"
          >
            <i className="mdi mdi-filter-remove align-bottom mr-1"></i> Clear
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );





  return (
    <React.Fragment>
      {console.log("moment().daysInMonth();", daysInMonth)}

      <Row className='mr-0 mb-2'>
        <Col md={12} className="pr-0">
          <div>
            <Toolbar left={leftContents} right={rightContents} />
          </div>
        </Col>
        <Col md={12} className="d-flex justify-content-end pr-0">


          <div className="search-box mt-2" style={{ width: "35%" }}>
            <div className="position-relative">
              <Input type="text" className="form-control-sm rounded" placeholder="Search student..." onChange={(e) => onSearch(e.target.value)} />
              <i className="mdi mdi-magnify search-icon" style={{ lineHeight: "30px" }}></i>
            </div>
          </div>

        </Col>




      </Row>

      <DataTable
        // lazy
        value={results}
        paginator
        totalRecords={results?.length}
        responsiveLayout="scroll"
        stripedRows
        showGridlines
        size="small"
        loading={isFetching}
        style={{ fontSize: "8px" }}
        // onPage={onPage}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown small"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        rows={lazyParams.rows}
        first={lazyParams.first}
        rowsPerPageOptions={[5, 10, 25]}

        scrollable scrollDirection="horizontal"
      >

        <Column field="image" header="Photo" body={imageBodyTemplate} headerStyle={{ width: '4rem' }} style={{ flexGrow: 1 }} frozen={true}  ></Column>
        <Column body={fullNameTemplate} header="Name" headerStyle={{ width: '10rem' }} alignFrozen="left" frozen={true} style={{ flexGrow: 1, flexBasis: '70px' }}></Column>
        {/* <Column body={(el) => (el.id)} header="id" headerStyle={{ width: '5rem' }} style={{ flexGrow: 1, flexBasis: '100px' }} alignFrozen="left" frozen={true}   ></Column> */}

        {
          Array(daysInMonth).fill(null).map((value, index) => (
            <Column body={(el) => (attendanceStatusTemplate(el, index + 1))} header={index + 1} headerStyle={{ width: '5rem' }} style={{ flexGrow: 1, flexBasis: '10px' }}></Column>
          ))
        }




      </DataTable>




    </React.Fragment>
  );
}

export default AttendanceMonthly; 