import React, { useEffect, useState } from 'react';
import { Card, CardBody, FormGroup, Label, Col, Row, Input, Button, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { toast } from 'react-toastify';

import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import { storage } from '../../utils/utils';
import { getStudents, } from '../../services/students';
import { Calendar } from 'primereact/calendar';
import NotificationsService from '../../components/Common/toaster';
import { getAttedanceReport, getStudentsAttedanceData } from '../../services/reports';
import moment from 'moment'
import { Toolbar } from 'primereact/toolbar';


const AttendanceDaily = ({ schoolInfo, ...props }) => {
  let typingTimeout = null;

  const history = useHistory()
  const [present, setPresent] = useState(false)
  const [absent, setAbsent] = useState(false)
  const [dates2, setDates2] = useState(new Date());



  const [lazyParams, setLazyParams] = useState({
    searchText: "",
    date: [],
    isPresent: "-1",
    first: 0,
    rows: 5,
    page: 1,
    sortField: "",
    sortOrder: 0,
    selectedClass: schoolInfo ? schoolInfo.class[0] : -1
  });

  // React=query clinet to refeatch data, etc.. oprations
  const queryClient = useQueryClient()

  //get Student Queries
  const { data: results, refetch: reload, isLoading, isFetching } =
    useQuery(['studentsReports', lazyParams, schoolInfo.objectId],
      () => getStudentsAttedanceData(lazyParams, schoolInfo.objectId), {
      keepPreviousData: true,
      // staleTime: 10000, // only eligible to refetch after 10 seconds
      onError: (error) => {
        toast.error(error.message);

      },
    })


  const onPage = (event) => {
    let data = event
    data.page = event.page + 1
    let _lazyParams = { ...lazyParams, ...data };
    setLazyParams(_lazyParams);
  }

  const onSearch = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {

      let _lazyParams = { ...lazyParams, searchText: text };
      setLazyParams(_lazyParams);
    }, 475);

  }

  const onClassChange = (text) => {

    let _lazyParams = { ...lazyParams, selectedClass: text };
    setLazyParams(_lazyParams);


  }


  const Offsymbol = (text) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {text}
      </div>
    );
  };

  const OnSymbol = (text) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingLeft: "15px"
        }}
      >
        {text}
      </div>
    );
  };


  const imageBodyTemplate = (rowData, { rowIndex }) => {

    let noPhotoUrl = "'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'"
    return <div className='text-center' key={"imgDiv" + rowIndex}>
      <img
        src={rowData.get("photo") ? rowData.get("photo")?._url : noPhotoUrl}
        onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
        alt={"Sorry"}
        width={50}
        height={50}
        className="product-image mb-1" />

    </div>
  }

  const fullNameTemplate = (rowData) => {
    let fName = rowData.get("studentname") ? rowData.get("studentname") : ""
    let mName = rowData.get("fathername") ? rowData.get("fathername") : "";
    let lName = rowData.get("surname") ? rowData.get("surname") : ""
    return <React.Fragment>
      <div>
        {fName + " " + mName + " " + lName}
      </div>
    </React.Fragment>
  }

  const inTimeTemplate = (rowData, { rowIndex }) => {

    let intime = rowData.attendancedata ? parseInt(rowData.attendancedata[0].intime) : ""
    // let intime =  parseInt(1657865192790)
    let updatedTime = intime == "" ? "---" : moment.utc(intime).local().format("LTS")
    // let t = "1656058563981"
    // let t = "1656058855756259"

    return <React.Fragment>
      <div className='textAlign-center'>

        {updatedTime}
      </div>
    </React.Fragment>
  }

  const outTimeTemplate = (rowData, { rowIndex }) => {

    let outtime = rowData.attendancedata ? parseInt(rowData.attendancedata[0].outtime) : ""
    // let outtime =  parseInt(1657865243312)

    let updatedTime = outtime == "" ? "---" : moment.utc(outtime).local().format("LTS")
    return <React.Fragment>
      <div className='textAlign-center'>
        {updatedTime}
      </div>
    </React.Fragment>
  }


  const attendanceStatusTemplate = (rowData, { rowIndex }) => {

    let isAbsent = rowData.attendancedata ? rowData.attendancedata[0].attendance === true ? false : true : "---"
    return <React.Fragment>
      <div className='textAlign-center'>
        <span className={`badge badge-soft-${isAbsent ? "danger" : "success"} mr-1 mb-2 bdg`}>{isAbsent ? isAbsent == "---" ? isAbsent : "Absent" : "Present "}</span>
      </div>
    </React.Fragment>
  }


  const getReportData = async (data) => {
    await getAttedanceReport(data , schoolInfo.objectId)
  }

  const leftContents = (
    <React.Fragment>
      <FormGroup>

        <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Attendance Status</Label>
        <Col md={12}>
          {<select className="custom-select custom-select-sm"
            onChange={(e) => {
              let _lazyParams = { ...lazyParams, isPresent: e.target.value }
              setLazyParams(_lazyParams);
            }}
          >
            <option defaultChecked value={"-1"}>All</option>
            <option defaultChecked value={true}>Present</option>
            <option defaultChecked value={false}>Absent</option>

          </select>
          }
        </Col>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="example-text-input" className="col-md-12 col-form-label pl-0">Select Class</Label>
        {<select className="custom-select custom-select-sm "
          onChange={(e) => { onClassChange(e.target.value) }}>
          {schoolInfo.class.map(item => <option key={item} value={item}>{item}</option>)}
        </select>
        }
      </FormGroup>
    </React.Fragment>
  );

  const rightContents = (
    <React.Fragment>
      <Row>

        <Col md={12} className="text-right" >

          <Calendar id="range" value={dates2}
            maxDate={new Date()}
            dateFormat="d MM yy"
            onChange={(e) => setDates2(e.value)}
            readOnlyInput
            inputStyle={{ fontStyle: "oblique", fontWeight: "bold" }}
            placeholder='Please select date-range' />
        </Col>
        <Col md={12} className="text-right" >
          <Button
            onClick={() => {
              let _lazyParams = { ...lazyParams, date: dates2 };
              setLazyParams(_lazyParams);
              // getReportData(dates2)
            }}
            color="primary"
            size="sm"
            className="waves-effect waves-light ml-2 mt-2"
          >
            <i className="ri-search-line align-bottom mr-1"></i> Search
          </Button>

          <Button
            onClick={() => {
              let _lazyParams = { ...lazyParams, date: [] };
              setLazyParams(_lazyParams);
              setDates2(new Date())
            }}
            color="light"
            size="sm"
            className="waves-effect waves-light ml-2 mt-2"
          >
            <i className="mdi mdi-filter-remove align-bottom mr-1"></i> Clear
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );

  return (
    <React.Fragment>


      <Row className='mr-0 mb-2'>
        <Col md={12} className="pr-0">
          <div>
            <Toolbar left={leftContents} right={rightContents} />
          </div>
        </Col>
        <Col md={12} className="d-flex justify-content-end pr-0">


          <div className="search-box mt-2" style={{ width: "35%" }}>
            <div className="position-relative">
              <Input type="text" className="form-control-sm rounded" placeholder="Search student by name , gr, phone , roll no..." onChange={(e) => onSearch(e.target.value)} />
              <i className="mdi mdi-magnify search-icon" style={{ lineHeight: "30px" }}></i>
            </div>
          </div>

        </Col>




      </Row>

      <DataTable
        // lazy
        value={results}
        paginator
        totalRecords={results?.length}
        responsiveLayout="scroll"
        stripedRows
        showGridlines
        size="small"
        loading={isFetching}
        // onPage={onPage}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown small"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        rows={lazyParams.rows}
        first={lazyParams.first}
        rowsPerPageOptions={[5, 10, 25]}


      >
        <Column field="image" header="Photo" body={imageBodyTemplate} headerStyle={{ width: '4rem' }} ></Column>
        <Column body={fullNameTemplate} header="Name" headerStyle={{ width: '20rem' }} ></Column>
        <Column body={attendanceStatusTemplate} header="Attendance Status" headerStyle={{ width: '4rem' }} ></Column>
        <Column body={inTimeTemplate} header="In Time"></Column>
        <Column body={outTimeTemplate} header="Out Time"></Column>
        {/* <Column body={(el) => el.get("phone1")} header="phone1 "></Column>
        <Column body={(el) => el.get("phone2")} header="phone2 "></Column> */}
        <Column body={(el) => el.get("class")} header="Class"></Column>




      </DataTable>




    </React.Fragment>
  );
}

export default AttendanceDaily; 