import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import { initializeParse } from '@parse/react';
import Parse from 'parse';
import globalStore from './globalStore';



initializeParse(
    'https://school.probietech.com/parse',
    'zjDlXWqwIv',
    'YOUR_JAVASCRIPT_KEY'
);

// initializeParse(
//     'http://69.10.48.170:1234/parse',
//     'zjDlXWqwIv',
//     'YOUR_JAVASCRIPT_KEY'
// );

// initializeParse(
//     'http://127.0.0.1:1337/parse',
//     'myAppId',
//     'YOUR_JAVASCRIPT_KEY'
//   );

Parse.enableEncryptedUser();
Parse.secret = 'A846D76D8FC74815F57A691141C54';
try {
    // console.log("first-index file", Parse?.User?.current())
    if (Parse.User.current()) globalStore.setState("currentUser", Parse.User.current());

} catch (error) {
    localStorage.clear();
    console.log("err", error)
}


const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
