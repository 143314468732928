import React, { useEffect, useState } from 'react';
import { Table, Card, CardBody, Media, Col, Row, Input, Button, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import SimpleBar from "simplebar-react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { toast } from 'react-toastify';
import { getSchoolById } from '../../services/schools';
import { useHistory } from "react-router-dom";
import { storage } from '../../utils/utils';
import { deleteStudent, getStudents, removeStudentPhoto } from '../../services/students';
import { Dialog } from 'primereact/dialog';
import NotificationsService from '../../components/Common/toaster';

import './order.css';
import Parse from 'parse'
import { saveOrders } from '../../services/orders';

const OrderList = ({ schoolInfo, ...props }) => {
  let typingTimeout = null;
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';


  const history = useHistory()
  const [present, setPresent] = useState(false)
  const [absent, setAbsent] = useState(false)

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [currentStudent, setCurrentStudent] = useState({});
  const [importLoader, setImportLoader] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileInfo, setFileInfo] = useState();
  const [importModal, setImportModal] = useState(false);
  const [failedFilesData, setFailedFilesData] = useState([]);
  const [isRemovingStudentImage, setIsRemovingStudentImage] = useState(false);


  const [selectAll, setSelectAll] = useState(false);
  const [selectedStudents, setSelecStudents] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isProcessingOrderLoading, setIsProcessingOrderLoading] = useState(false);



  const [lazyParams, setLazyParams] = useState({
    searchText: "",
    first: 0,
    rows: 5,
    page: 1,
    sortField: "",
    sortOrder: 0,
    selectedClass: -1,
    isOrdered: -1,
  });

  // React=query clinet to refeatch data, etc.. oprations
  const queryClient = useQueryClient()

  //get Student Queries
  const { data: results, refetch: reload, isLoading, isFetching } = useQuery(['OrderStudents', lazyParams, schoolInfo.objectId],
    () => getStudents(lazyParams, schoolInfo.objectId), {
    keepPreviousData: true,
    // staleTime: 10000, // only eligible to refetch after 10 seconds
    onSuccess: (data) => {


      if (lazyParams.selectedClass != -1) {
        let selectedStudent = selectedStudents;
        let total = selectedStudent?.filter(res => res.get("class") === lazyParams.selectedClass);
        if (total?.length >= data?.count) {
          setSelectAll(true)
        } else {
          setSelectAll(false)

        }
      } else {
        let selectedStudent = selectedStudents;
        if (selectedStudent?.length == data?.count) {
          setSelectAll(true)
        } else {
          setSelectAll(false)

        }
      }

    },

    onError: (error) => {
      toast.error(error.message);

    },
  })

  // Mutations to delete student
  const { mutateAsync: deleteMuted, isLoading: isDeleting } = useMutation(deleteStudent, {

    onSuccess: (data) => {
      const previousValue = queryClient.getQueryData('Students', { exact: false });
      const updatedValue = [...previousValue.results];
      const removeDeleted = updatedValue.filter(
        eachValue => eachValue.id !== data.id);
      previousValue.results = removeDeleted
      queryClient.setQueryData("Students", previousValue);
      NotificationsService.success("User removed successfully")
      // queryClient.invalidateQueries('users')

    },
    onError: (error) => {
      NotificationsService.error(error.message);

    }
  })

  // Mutations to delete student photo from server
  const { mutateAsync: removeStudentImageMuted, isLoading: isImageDeleting } = useMutation(removeStudentPhoto, {

    onSuccess: (data) => {
      const previousValue = queryClient.getQueryData(['Students', lazyParams, schoolInfo.id], { exact: false });
      const updatedValue = [...previousValue.results];

      const removeDeleted = updatedValue.map((el) => {
        if (el.id == data.id) {
          // let item = el.get("photo")
          // item._url = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
          el.set("photo", "")

        }
        return el
      });


      previousValue.results = removeDeleted
      queryClient.setQueryData("Students", previousValue);
      NotificationsService.success("Student photo removed successfully")
      // queryClient.invalidateQueries('Students')

    },
    onError: (error) => {
      NotificationsService.error(error.message);

    }
  })

  const onPage = (event) => {
    let data = event
    data.page = event.page + 1
    let _lazyParams = { ...lazyParams, ...data };
    setLazyParams(_lazyParams);
  }

  const onSearch = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {

      let _lazyParams = { ...lazyParams, searchText: text };
      setLazyParams(_lazyParams);
    }, 475);

  }

  const onClassChange = (text) => {
    let _lazyParams = { ...lazyParams, selectedClass: text };
    setLazyParams(_lazyParams);

  }

  const onCardStatusChange = (text) => {
    let _lazyParams = { ...lazyParams, isOrdered: text };
    setLazyParams(_lazyParams);

  }


  const actionBodyTemplate = (rowData) => {
    let data = JSON.parse(JSON.stringify(rowData))
    let newData = {
      objectId: data.objectId,
      studentname: data.studentname,
      fathername: data.fathername,
      surname: data.surname,
      mothername: data.mothername,
      class: data.class,
      phone1: data.phone1,
      phone2: data.phone2,
      dob: data.dob,
      bg: data.bg,
      address: data.address,
      aadhar: data.aadhar,
      ...(data.photo) && { photo: data.photo },
      grno: data.grno,
      rfid: data.rfid,
      qr: data.qr
    }
    return <React.Fragment>
      <div className="d-flex">

        <span onClick={() => {
          history.push(
            {
              pathname: `/schools/${storage.getSchool().objectId}/addstudent`,
              state: { detail: newData }
            }


          )
        }} className="mr-3 text-primary" id="edit1" style={{ cursor: "pointer" }}><i className="ri-pencil-line font-size-18"></i></span>
        <UncontrolledTooltip placement="auto" target="edit1">
          Edit
        </UncontrolledTooltip >

        <span onClick={() => confirmOrder(rowData)} className="text-danger" id="delete1"
          style={{ cursor: "pointer" }}><i className="ri-delete-bin-6-line font-size-18"></i></span>
        <UncontrolledTooltip placement="auto" target="delete1">
          Delete
        </UncontrolledTooltip >


      </div>
    </React.Fragment>
    // <button className="btn-outline-primary btn-rounded waves-effect add-btn float-right ml-auto float-right"
    // onClick={() => { window.$('#Create').modal('show') }}> <i class="far fa-edit"></i> </button>;
  }


  const imageBodyTemplate = (rowData, { rowIndex }) => {
    let photoNo = rowData.get("photono")
    let noPhotoUrl = "'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'"
    return <div className='text-center' key={"imgDiv" + rowIndex}>
      <img
        src={rowData.get("photo") ? rowData.get("photo")?._url : noPhotoUrl}
        onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
        alt={"Sorry"}
        width={50}
        height={50}
        className="product-image mb-1" />

    </div>
  }

  const fullNameTemplate = (rowData) => {
    let fName = rowData.get("studentname") ? rowData.get("studentname") : ""
    let mName = rowData.get("fathername") ? rowData.get("fathername") : "";
    let lName = rowData.get("surname") ? rowData.get("surname") : ""
    return <React.Fragment>
      <div>
        {fName + " " + mName + " " + lName}
      </div>
    </React.Fragment>
  }


  const confirmOrder = () => {
    setConfirmDialog(true);
  }

  const hideConfirmOrderDialog = () => {
    setConfirmDialog(false);
    setIsProcessing(false)
  }

  const confirmOrderDialogFooter = () => (
    <React.Fragment>
      {!isProcessing ? <div><Button type="button" color="light" onClick={hideConfirmOrderDialog} >No</Button>
        <Button type="button" color="primary" onClick={() => {
          setIsProcessing(true)
          setIsProcessingOrderLoading(true)
          handelSubmitOrder()
        }}>Yes</Button>
      </div>
        : <Button type="button" color="primary" disabled={isProcessingOrderLoading} onClick={() => {

          hideConfirmOrderDialog()
        }}>{isProcessingOrderLoading ? "Please wait..." : "Done"}</Button>
      }

    </React.Fragment>

  );


  const onSelectionChange = (event) => {
    const value = event.value;
    setSelecStudents(value);
    setSelectAll(value.length === results?.count);
  }

  const onSelectAllChange = (event) => {
    const selectAll = event.checked;
    if (selectAll) {


      let _lazyParams = { ...lazyParams };
      _lazyParams.first = 0
      _lazyParams.rows = results?.count
      getStudents(_lazyParams, schoolInfo.objectId).then(data => {
        setSelectAll(true);

        if (selectedStudents && _lazyParams.selectedClass != -1) {
          let update = [...data.results, ...selectedStudents]
          setSelecStudents(update);
        } else {
          setSelecStudents(data.results);
        }
      });
    }
    else {

      if (lazyParams.selectedClass != -1) {
        let selectedStudent = selectedStudents;
        let updatedValue = selectedStudent?.filter(res => res.get("class") != lazyParams.selectedClass);
        setSelecStudents(updatedValue)
        setSelectAll(false);
      } else {
        setSelectAll(false);
        setSelecStudents([]);
      }
    }
  }

  const getClassWiseTotal = (classToFind) => {
    let studentsCount = selectedStudents
    let total = studentsCount?.filter(res => res.get("class") === classToFind);
    if (total) {
      return total.length
    } else {
      return 0
    }
  }

  const renderListItem = (rowData, { rowIndex }) => {
    let jData = JSON.parse(JSON.stringify(rowData))

    let noPhotoUrl = "'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'"
    let fName = rowData.get("studentname") ? rowData.get("studentname") : ""
    let mName = rowData.get("fathername") ? rowData.get("fathername") : "";
    let lName = rowData.get("surname") ? rowData.get("surname") : ""
    let fullName = fName + " " + mName + " " + lName
    let address = rowData.get("address")
    let className = rowData.get("class")
    let phone1 = rowData.get("phone1")
    let phone2 = rowData.get("phone2")
    let dob = rowData.get("dob")
    let grno = rowData.get("grno")
    let bg = rowData.get("bg")
    let rollno = rowData.get("rollno")
    let aadhar = rowData.get("aadhar")
    let isOrdered = rowData.get("isOrdered")
    let objectId = rowData.id
    let mothername = rowData.get("mothername")
    let rfid = rowData.get("rfid")
    let qr = rowData.get("qr")


    let newData = {
      objectId,
      studentname: fName,
      fathername: mName,
      surname: lName,
      mothername,
      class: className,
      phone1,
      phone2,
      dob,
      bg,
      address,
      aadhar,
      ...(jData.photo) && { photo: jData.photo },
      grno,
      rfid,
      qr
    }

    return (
      <div className="col-12">

        <Media className="">
          <div>
            {/* <div class={rowIndex % 2 == 0 ? "ribbon warning" : "ribbon success"}>
              <span>{rowIndex % 2 == 0 ? "Pending" : "Completed"}</span>

            </div> */}

            <div className="ribbon-wrapper">
              <div className={!isOrdered ? "ribbons warning" : "ribbons success"}>{!isOrdered ? "Pending" : "Sent"}</div>

            </div>


            <img
              className='stv'
              src={rowData.get("photo") ? rowData.get("photo")?._url : noPhotoUrl}
              onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
              alt="sorry no Img found"
            />
          </div>
          <Media body>
            <h5 className="font-size-15 mt-3">{fullName}</h5>
            <p className="text-muted mb-2">{address}</p>

            <ul className="list-inline product-review-link mb-0">
              <li className="list-inline-item">
                <span ><i className="ri-phone-line align-bottom mr-1"></i> {phone1 ? phone1 : "N/A"}</span>
              </li>
              <li className="list-inline-item ml-1">
                <span ><i className="ri-phone-line align-bottom mr-1"></i> {phone2 ? phone2 : "N/A"}</span>
              </li>
              <li className="list-inline-item">
                <span ><i className="ri-calendar-line align-bottom mr-1"></i> {dob}</span>
              </li>
              <li className="list-inline-item ml-2">
                <span ><i className="mdi mdi-blood-bag align-bottom mr-1"></i> {bg}</span>
              </li>

            </ul>
            <ul className="list-inline product-review-link mt-2 mb-0">
              <li className="list-inline-item mr-5">
                <span ><i className="align-bottom mr-1 font-weight-bold">G.r. No. :</i> {grno}</span>
              </li>
              <li className="list-inline-item mr-5">
                <span ><i className="align-bottom mr-1 font-weight-bold">Roll No. :</i> {rollno}</span>
              </li>
              <li className="list-inline-item">
                <span ><i className="align-bottom mr-1 font-weight-bold">Aadhaar No.:</i> {aadhar}</span>
              </li>

            </ul>
            <ul className="list-inline product-review-link mb-0">



            </ul>
            <ul className="list-inline product-review-link mb-0">



            </ul>
          </Media>
          <div className="text-center mt-3" style={{ display: "inline-grid" }}>
            {/* <div class={`product-ribbon badge badge-dark mt-2`}>{className}</div> */}

            <p className="float-sm-right font-size-12 font-weight-bolder">{className}</p>

            {/* <div class={`product-ribbon badge badge-${rowIndex % 2 == 0 ? "warning" : "success"} mt-2`}>{rowIndex % 2 == 0 ? "Pending" : "Completed"}</div> */}

            {/* <span className={`badge badge-soft-${rowIndex % 2 == 0 ? "warning" : "success"} font-size-11 mt-2`}>{rowIndex % 2 == 0 ? "Pending" : "Completed"}</span> */}

            <button
              onClick={() => {
                history.push(
                  {
                    pathname: `/schools/${storage.getSchool().objectId}/addstudent`,
                    state: { detail: newData }
                  }


                )
              }} className="waves-effect waves-light mr-1 btn btn-primary btn-sm mt-2">Edit Details</button >
          </div>
        </Media >
        {/* <div className="product-list-item">
          <img
            src={rowData.get("photo") ? rowData.get("photo")?._url : noPhotoUrl}
            onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
          />
          <div className="product-list-detail">
            <div className="product-name">{fullName}</div>
            <div className="product-description">{address}</div>

            <i className="pi pi-tag product-category-icon"></i><span className="product-category">data.category</span>
          </div>
          <div className="product-list-action">
            <span className="product-price">{className}</span>
            <span className={`product-badge status-instock`}>instock</span>
          </div>
        </div> */}
      </div >
    );
  }

  const handelSubmitOrder = async () => {
    let schoolLinkData = await getSchoolById(schoolInfo?.objectId)
    let students = selectedStudents.map(value => value.id);
    let tmp = []
    schoolInfo.class.map(el => {
      let classTotal = getClassWiseTotal(el)
      if (classTotal > 0) {
        let data = { "class": el, "classTotal": classTotal }
        tmp.push(data)
      }
    })

    let schoolId = schoolLinkData
    let schoolName = schoolLinkData.get("name")
    let status = 0
    let orderSummary = { schoolName, "totalStudent": students.length, "details": tmp }
    let createdBy = Parse.User.current().id
    let data = { schoolId, status, orderSummary, createdBy, students }


    saveOrders(data).then(res => {
      setIsProcessingOrderLoading(false)
      queryClient.invalidateQueries('OrderStudents')
      setSelecStudents([])

    }).catch(err => {
      NotificationsService.error(err.message)
      setIsProcessingOrderLoading(false)


    })


  }

  return (
    <React.Fragment>

      <Row>
        <Col md={9} >
          <Row className='mr-0 mb-2'>
            <Col md={4} style={{ textAlign: "left" }} className="d-flex"  >


              <div className="search-box ml-0 mt-2" style={{ width: "65%" }}>
                <div className="position-relative">
                  <Input type="text" className="form-control-sm rounded" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} />
                  <i className="mdi mdi-magnify search-icon" style={{ lineHeight: "30px" }}></i>
                </div>
              </div>

            </Col>
            <Col md={8} style={{ textAlign: "end" }} className="p-0">




              {<select className="custom-select custom-select-sm  ml-2 mt-2" style={{ width: "20%" }}
                onChange={(e) => { onClassChange(e.target.value) }}>
                <option defaultValue value={-1}>Class-All</option>
                {schoolInfo.class.map(item => <option key={item} value={item}>{item}</option>)}
              </select>
              }

              {<select id="cardStatus" className="custom-select custom-select-sm  ml-2 mt-2" style={{ width: "25%" }}
                onChange={(e) => { onCardStatusChange(e.target.value) }}
              >
                <option value={-1}>All Students</option>
                <option defaultValue value={false}>Pending Students</option>
                <option value={true}>Sent Students</option>

              </select>
              }

            </Col>


          </Row>

          <DataTable
            lazy
            value={results?.results}
            paginator
            totalRecords={results?.count}
            responsiveLayout="scroll"
            stripedRows
            // showGridlines
            size="small"
            loading={isFetching}
            onPage={onPage}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown small"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={lazyParams.rows}
            first={lazyParams.first}
            rowsPerPageOptions={[5, 10, 25]}
            // selectionMode="checkbox"
            paginatorPosition={'both'}
            dataKey="id"
            selection={selectedStudents}
            onSelectionChange={onSelectionChange}
            selectAll={selectAll}
            onSelectAllChange={onSelectAllChange}
            style={{ "border": "1px solid #E9ECEF" }}
            emptyMessage="No record found."
          >
            <Column selectionMode="multiple" headerStyle={{ width: '3em' }} ></Column>
            {/* <Column field="image" header="Photo" body={imageBodyTemplate} headerStyle={{ width: '4rem' }} ></Column> */}
            {/* <Column field='surname' header="Phone2"></Column> */}

            {/* <Column body={fullNameTemplate} header="Name" headerStyle={{ width: '20rem' }} ></Column> */}
            <Column body={renderListItem} header="" ></Column>
            {/* <Column body={(el) => el.get("class")} header="Class"></Column>
            <Column body={(el) => el.get("phone1")} header="Phone1"></Column>
            <Column body={(el) => el.get("phone2")} header="Phone2"></Column>
            <Column body={(el) => el.get("dob")} header="D.O.B."></Column>
            <Column body={(el) => el.get("bg")} header="B.G."></Column>
            <Column body={(el) => el.get("grno")} header="G.R."></Column>
            <Column body={(el) => el.get("aadhar")} header="Aadhar No."></Column>
            <Column field="address" body={(el) => el.get("address")} header="Address"></Column>
            <Column headerStyle={{ width: '4rem' }} header="Action" body={actionBodyTemplate}></Column> */}


          </DataTable>

          <Dialog visible={confirmDialog}
            header="Confirm"
            modal
            footer={confirmOrderDialogFooter}
            onHide={hideConfirmOrderDialog}
            closable={false}
          >
            <div className="confirmation-content">
              {!isProcessing ? <i className="pi pi-exclamation-triangle mr-2 " style={{ fontSize: '2rem' }} /> :
                <i className="ri-checkbox-circle-line mr-2 align-bottom" style={{ fontSize: '2rem' }} />}
              {!isProcessing ? <span className=''>
                Are you sure that you want to complete the order of <br></br>
                <b><i>TOTAL STUDENT : </i> {selectedStudents?.length}</b>

              </span>
                :
                <span className=''>Thank you for placing order.<br />
                  Check order status inside history tab after comeplting process.</span>}
            </div>
          </Dialog>

        </Col>
        <Col md={3}>
          <label>Order Details</label>
          <Card>
            {/* <CardBody>
              <Media>
                <Media body className="overflow-hidden">
                  <p className="text-truncate font-size-14 mb-2">Total Students</p>
                  <h4 className="mb-0">1452</h4>
                </Media>
                <div className="text-primary">
                  <i className={" ri-stack-line font-size-24"}></i>
                </div>
              </Media>
            </CardBody> */}

            <CardBody className="border-top py-3">

              <div className="text-center">
                <div className="text-primary">
                  <i className={"ri-mickey-line font-size-24"}></i>
                </div>
                <p className="mb-2">Total Student(s)</p>
                <h4>{selectedStudents ? selectedStudents.length : 0}</h4>


              </div>

              <SimpleBar style={{ maxHeight: "300px" }}>
                <div className="table-responsive mt-4">
                  <Table hover className=" mb-0 table-centered table-nowrap">
                    <tbody>
                      {schoolInfo.class.map((el, index) =>
                        <tr key={index}>
                          <td>
                            {/* <h5 className="font-size-14 mb-0">10 C - ENG</h5> */}
                            <span className="badge badge-soft-primary font-size-11 mr-1">{el}</span>
                          </td>

                          <td>
                            <p className="text-muted mb-0">{getClassWiseTotal(el)}</p>
                          </td>
                        </tr>
                      )}
                      {/* <tr>
                        <td>
                          <h5 className="font-size-14 mb-0">10 C - ENG</h5>
                        </td>
                        <td><div id="spak-chart1"></div></td>
                        <td>
                          <p className="text-muted mb-0">53</p>
                        </td>
                      </tr> 
                      */}

                    </tbody>
                  </Table>
                </div>
              </SimpleBar>

              <div className="text-center mt-4">
                <button onClick={() => confirmOrder()} className="btn btn-primary btn-sm" disabled={selectedStudents?.length == 0}>Place order</button>
              </div>
            </CardBody>

          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default OrderList; 