import Parse from 'parse';
import moment from 'moment'



export const getStudentsAttedanceData = async (filters = {}, schoolId, fields = 'name,address') => {

    // schoolId = "BzpytMkBpf"

    let queryFilters = filters
    const { rows, page, first, searchText, sortField, sortOrder, selectedClass, isOrdered = -1, date = "", isPresent = -1, isMonthView = false } = queryFilters
    const parseCode = new Parse.Object("Students_" + schoolId);
    const attendanceQuery = new Parse.Query("Attendance_" + schoolId);


    let start = moment(date).startOf(isMonthView ? 'month' : 'day')
    let end = moment(date).endOf(isMonthView ? 'month' : 'day')
    attendanceQuery.greaterThanOrEqualTo("createdAt", start.toDate())
    attendanceQuery.lessThanOrEqualTo("createdAt", end.toDate())
    isPresent != -1 && attendanceQuery.equalTo("attendance", isPresent == "true" ? true : false);
    const attedanceQueryResults = await attendanceQuery.find();

    const queries = [];

    if (searchText) {
        queries.push(new Parse.Query(parseCode).matches('studentname', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('fathername', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('surname', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('mothername', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('phone1', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('phone2', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('rollno', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('grno', searchText, "i"));
    }


    const mainQuery = searchText ? new Parse.Query.or(...queries) : new Parse.Query("Students_" + schoolId);
    // mainQuery.skip(first)
    // mainQuery.limit(rows)
    switch (sortOrder) {
        case -1:
            mainQuery.descending(sortField);
            break;
        case 1:
            mainQuery.ascending(sortField);
            break;
        default:
            mainQuery.ascending("studentname");
            break;
    }
    selectedClass != -1 && mainQuery.equalTo("class", selectedClass)

    let studentList = await mainQuery.find();

    if (attedanceQueryResults.length > 0) {
        studentList = studentList.map(item => {
            let x = attedanceQueryResults.filter(el => el.get("studentId") == item.id)
            if (x.length != 0) {
                item.attendancedata = JSON.parse(JSON.stringify(x))
            }

            return item
        })
    }
    // mainQuery.withCount();
    // mainQuery.select(fields);
    if (isPresent != -1) {
        console.log("studentList", studentList)
        let x = studentList.filter(el => el.attendancedata !== undefined)
        return x
    }

    return studentList
}

export const getAttedanceReport = async (data, schoolId) => {
    let start = moment(data).startOf('day')
    let end = moment(data).endOf('day')
    const studentList = new Parse.Query("Students_" + schoolId);
    const attendanceQuery = new Parse.Query("Attendance_" + schoolId);
    attendanceQuery.greaterThanOrEqualTo("createdAt", start.toDate())
    attendanceQuery.lessThanOrEqualTo("createdAt", end.toDate())
    // attendanceQuery.matchesKeyInQuery("studentId", "objectId", studentList);
    const results = await attendanceQuery.find();
    studentList.withCount();
    let queryTableAResults = await studentList.find();

    let newData = queryTableAResults.results.map(item => {
        let x = results.filter(el => el.get("studentId") == item.id)
        if (x.length != 0) {
            item.attendance = x
        }
        return item
    })
    queryTableAResults.results = newData

    // for (let result of queryTableAResults) {
    //     // Get all entries from JOIN query that have a link to this TableA entry
    //     let joinQueryResultsFiltered = results.filter(
    //         (joinQueryResult) =>
    //             joinQueryResult.get("studentId") !== undefined &&
    //             joinQueryResult.get("studentId") == result.id
    //     );
    //     if (joinQueryResultsFiltered.length > 0) {
    //         for (let joinResult of joinQueryResultsFiltered) {
    //             let fieldBValue = joinResult.get("attendance");
    //         }
    //     } else {
    //     }
    // }


} 