import React, { Component, useState, useEffect } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, CardBody, Card } from "reactstrap";
import classnames from 'classnames';



//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import StudentList from '../StudentList/StudentList';
import UserList from '../User/UserList';
import AddSchoolForm from '../SchoolAdd/SchoolAddEdit';
import { Link, useHistory, useParams } from "react-router-dom";
import { getSchoolById } from '../../services/schools';
import { storage } from '../../utils/utils';


const AdminUser = ({ title }) => {


    const [schoolInfo, setSchoolInfo] = useState()
    const [activeTab, setActiveTab] = useState("1")
    const [breadcrumbItems] = useState([

    ]);







    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="User List" breadcrumbItems={breadcrumbItems} />


                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="pt-0 mb-3">


                                     < UserList />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
}

// class StarterPageSchoolDetails1 extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             breadcrumbItems: [
//                 { title: "Colorbenz", link: "#" },
//                 { title: "Dashboard", link: "#" },
//             ],
//         }
//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="page-content">
//                     <Container fluid>

//                         <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />

//                     </Container>
//                 </div>
//             </React.Fragment>
//         );
//     }
// }

export default AdminUser;