import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import schools from "../pages/Schools/index";
import schoolDetails from "../pages/SchoolDetails/index";
import schoolAdd from "../pages/SchoolAdd/index";
import StudentAdd from "../pages/StudentAdd";
import Parse from 'parse';
import StudentDetails from "../pages/SchoolAdminPages";
import Orders from "../pages/Orders";
import Profile from "../pages/Profile";
import AdminUser from "../pages/User";
import ReportsBase from "../pages/Reports";


const authProtectedRoutes = [

	{ path: "/dashboard", component: StudentDetails, title: "Student List" },
	{ path: "/users", component: StudentDetails, title: "User List" },
	{ path: "/setting", component: StudentDetails, title: "Update School List" },
	{ path: "/orders", component: Orders },
	{ path: "/schools/:id/addstudent", component: StudentAdd },
	{ path: "/profile", component: Profile },
	{ path: "/reports", component: ReportsBase },
	{ path: "/addstudent", component: StudentAdd },
	// { path: "/students", component: StudentList },

	// { path: "/details", component: schoolDetails },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const authProtectedSuperAdminRoutes = [


	{ path: "/orders", component: Orders },
	{ path: "/addschool", component: schoolAdd },
	{ path: "/schools/:id/addstudent", component: StudentAdd },
	{ path: "/schools/:id", component: schoolDetails },
	{ path: "/schools", component: schools },
	{ path: "/addstudent", component: StudentAdd },
	{ path: "/profile", component: Profile },
	{ path: "/reports", component: ReportsBase },
	{ path: "/users", component: AdminUser, title: "User List" },

	{ path: "/", exact: true, component: () => <Redirect to="/schools" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },
	{ path: "/auth-lock-screen", component: AuthLockScreen },
];

export { authProtectedRoutes, publicRoutes, authProtectedSuperAdminRoutes };