import React, { Component, useState, useEffect } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, CardBody, Card } from "reactstrap";
import classnames from 'classnames';



//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import StudentList from '../StudentList/StudentList';
import UserList from '../User/UserList';
import AddSchoolForm from '../SchoolAdd/SchoolAddEdit';
import { getSchoolById } from '../../services/schools';
import { isSuperAdmin, storage } from '../../utils/utils';
import OrderList from './orderList';
import OrderHistory from './orderHistory';


const Orders = ({ title }) => {

    const [schoolInfo, setSchoolInfo] = useState()
    const [activeTab, setActiveTab] = useState("1")
    const [breadcrumbItems] = useState([]);



    useEffect(() => {

    }, [breadcrumbItems, title]);

    useEffect(() => {
        let schoolData = storage.getSchool()
        console.log("schoolData", schoolData)
        setSchoolInfo(schoolData)

    }, [])

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Orders" breadcrumbItems={breadcrumbItems} />


                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody >
                                    {!isSuperAdmin() && <Nav tabs className="nav-tabs-custom mb-4">
                                        <NavItem>
                                            <NavLink onClick={() => { toggleTab('1'); }}
                                                className={classnames({ active: activeTab === '1' }, "font-weight-bold p-3")}>
                                                Order ( ID-card )</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink onClick={() => { toggleTab('2'); }}
                                                className={classnames({ active: activeTab === '2' }, "p-3 font-weight-bold")}>
                                                Order History</NavLink>
                                        </NavItem>

                                    </Nav>}
                                    {!isSuperAdmin() && activeTab == 1 && schoolInfo && < OrderList schoolInfo={schoolInfo} />}
                                    {!isSuperAdmin() && activeTab == 2 && schoolInfo && < OrderHistory schoolInfo={schoolInfo} />}
                                    {isSuperAdmin()  && < OrderHistory  />}


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
}


export default Orders;