import axios from "../axios-school";
import Parse from 'parse';

export const getSchools = (payload) => {
    const { rows, page } = payload
    return axios.get("/schools", { params: { limit: rows, page: page } })
};

export const getSchoolById = (id) => {
    const parseCode = new Parse.Object('schools');
    const mainQuery = new Parse.Query(parseCode)
    mainQuery.equalTo("objectId", id)
    return mainQuery.first()
};

export const saveSchool = (data) => {
    const { name, address, desc, shift, classes, isEdit = false } = data

    console.log("newSchoolEntry", data)

    const newSchoolEntry = new Parse.Object('schools');
    isEdit && newSchoolEntry.set("objectId", data.objectId)
    let updatedClasses = classes.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
    newSchoolEntry.set("name", name);
    newSchoolEntry.set("address", address);
    newSchoolEntry.set("description", desc);
    newSchoolEntry.set("shifttime", shift);
    !isEdit && newSchoolEntry.set("class", updatedClasses);
    return newSchoolEntry.save()
}

export const getSchool = async (filters = {}, fields = 'name,address') => {
    const parseCode = new Parse.Object('schools');
    let queryFilters = filters
    const { rows, page, first, searchText, sortField, sortOrder } = queryFilters
    console.log("queryFilters", filters)
    const queries = [];

    if (searchText) {
        queries.push(new Parse.Query(parseCode).matches('address', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('name', searchText, "i"));
    }


    const mainQuery = searchText ? new Parse.Query.or(...queries) : new Parse.Query("schools");
    mainQuery.skip(first)
    mainQuery.limit(rows)
    switch (sortOrder) {
        case -1:
            mainQuery.descending(sortField);
            break;
        case 1:
            mainQuery.ascending(sortField);
            break;
        default:
            mainQuery.descending("createdAt");
            break;
    }

    mainQuery.withCount();
    mainQuery.select(fields);
    return mainQuery.find()
}

export const updateStudentClass = async (data) => {

    const { classToFind, ClassToUpdate, schoolId } = data
    const parseCode = new Parse.Object('Students_' + schoolId);
    console.log(classToFind, ClassToUpdate, schoolId)
    const mainQuery = new Parse.Query(parseCode)
    mainQuery.equalTo("class", classToFind)
    try {
        if (ClassToUpdate) {
            const results = await mainQuery.find();
            console.log("results", results)
            for (const result of results) {
                result.set('class', ClassToUpdate)
            }
            let dataforstudent = await Parse.Object.saveAll(results)
            return await updateSchoolClass(data)
        } else {
            let checkForData = await mainQuery.find();
            if (checkForData.length > 0) {
                throw new Error("You cant delete this class , Please remove student(s) from this class")
            } else {
                return await updateSchoolClass(data)

            }
        }

    } catch (e) { throw e }
};

export const updateSchoolClass = async (data) => {
    console.log("data for  school class" , data)
    let updatedClasses = data.newList.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
    const newCodeEntry = new Parse.Object('schools');
    newCodeEntry.set("objectId", data.schoolId);
    newCodeEntry.set("class", updatedClasses);
    return newCodeEntry.save()

};

export const deleteStudentClass = async (data) => {
    const { classToFind, schoolId } = data
    const parseCode = new Parse.Object('Students_' + schoolId);

    const mainQuery = new Parse.Query(parseCode)
    mainQuery.equalTo("class", classToFind)
    try {
        return await mainQuery.find();


    } catch (e) { throw e }
};

export const deleteSchool = async (schoolId) => {

    let data = { className: "Students_" + schoolId, schoolId }
    let schemas = [{ className: "Students_" + schoolId }, { className: "Attendance_" + schoolId }]

    try {

        // const obj = { "__type": "Pointer", className: "schools", "objectId": schoolId };
        // const mainQuery = new Parse.Query("_User");
        // mainQuery.equalTo('schoolId', obj)
        // let data = await mainQuery.find()



        // data.forEach(element => {
        //     element.destroy()
        // });

        // const sessionQuery = new Parse.Query("_Session");
        // sessionQuery.equalTo('user', "VFbYx1QODl")
        // sessionQuery.include('user')
        // let datas = await sessionQuery.find()
        // console.log("datas",datas)
        // return datas.forEach(element => {
        //     console.log("element",element)
        // });


        /*  First Remove All student from table and remove files 
            second Remove Schema from Db
            third Remove Associates Users and thier session
            fourth Remove School from table */

        for (let index = 0; index < schemas.length; index++) {

            const element = schemas[index];
            if (element.className.startsWith("Students_")) {
                let removeAllStudent = await Parse.Cloud.run("removeAllStudent", data)
                console.log("step-1", removeAllStudent)
            }
            let removeSchema = await Parse.Cloud.run("removeSchema", element)
            console.log("step-2", removeSchema)


        }

        let deleteSchoolUser = await Parse.Cloud.run("deleteSchoolUser", data)
        console.log("step-3", deleteSchoolUser)

        const updateEntry = new Parse.Object("schools");
        updateEntry.set("objectId", schoolId)
        return updateEntry.destroy()



    } catch (e) { throw e }
};
