import axios from "../axios-school";
import Parse from 'parse';
import { updateSchoolClass } from "./schools";

export const getSchools = (payload) => {
    const { rows, page } = payload
    return axios.get("/schools", { params: { limit: rows, page: page } })
};

export const getSchoolById = (id) => {
    const parseCode = new Parse.Object('schools');
    const mainQuery = new Parse.Query(parseCode)
    mainQuery.equalTo("objectId", id)
    return mainQuery.first()
};

export const saveStudent = async (data) => {
    const { photo, schoolId } = data

    const newEntry = new Parse.Object("Students_" + schoolId);



    Object.keys(data).map(key =>

        key != "schoolId" && key != "photo" && newEntry.set(key == "classes" ? "class" : key, data[key].toString())
    )
    if (photo) {
        const base64 = photo
        const parseFile = new Parse.File("student.png", { base64: base64 });
        let newfile = await parseFile.save()
        newEntry.set("photo", newfile);
    }
    return newEntry.save()

    // newEntry.set("address", address);
    // newEntry.set("bg", bg);
    // newEntry.set("classes", classes);
    // newEntry.set("division", division);
    // newEntry.set("dob", dob);
    // newEntry.set("firstname", firstname);
    // newEntry.set("middlename", middlename);
    // newEntry.set("lastname", lastname);
    // newEntry.set("grno", grno);
    // newEntry.set("phone1", phone1);
    // newEntry.set("phone2", phone2);
    // newEntry.set("rfid", rfid);
    // newEntry.set("house", house);

}

export const updateStudent = async (data, isEdit = false) => {
    const { photo, schoolId, objectId = null } = data
    let qr = isEdit ? data.qr : await Parse.Cloud.run("genrateQrCode")

    const updateEntry = new Parse.Object("Students_" + schoolId);

    if (isEdit) {

        updateEntry.set("objectId", objectId)
        updateEntry.set("isOrdered", false)

    }
    if (!isEdit) {

        updateEntry.set("qr", qr)

    }
    updateEntry.set("photono", qr)
    Object.keys(data).map(key =>

        key !== "schoolId" && key !== "objectId" && key !== "photo" && updateEntry.set(key === "classes" ? "class" : key, data[key]?.toString() == "-1" ? "" : data[key]?.toString())
    )

    if (photo) {
        const base64 = photo
        const parseFile = new Parse.File(qr, { base64: base64 });
        let newfile = await parseFile.save()
        updateEntry.set("photo", newfile);
    }

    return updateEntry.save()



}

export const getStudents = async (filters = {}, schoolId, fields = 'name,address') => {

    // schoolId = "BzpytMkBpf"

    let queryFilters = filters
    const { rows, page, first, searchText, sortField, sortOrder, selectedClass, isOrdered = -1 } = queryFilters
    const parseCode = new Parse.Object("Students_" + schoolId);

    const queries = [];

    if (searchText) {
        queries.push(new Parse.Query(parseCode).matches('address', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('studentname', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('fathername', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('surname', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('mothername', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('class', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('bg', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('phone1', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('phone2', searchText, "i"));
        queries.push(new Parse.Query(parseCode).matches('rollno', searchText, "i"));
    }


    const mainQuery = searchText ? new Parse.Query.or(...queries) : new Parse.Query("Students_" + schoolId);
    mainQuery.skip(first)
    mainQuery.limit(rows)
    switch (sortOrder) {
        case -1:
            mainQuery.descending(sortField);
            break;
        case 1:
            mainQuery.ascending(sortField);
            break;
        default:
            mainQuery.ascending("studentname");
            break;
    }
    selectedClass != -1 && mainQuery.equalTo("class", selectedClass)
    isOrdered != -1 && mainQuery.equalTo("isOrdered", isOrdered == "false" ? false : true)
    mainQuery.withCount();
    // mainQuery.select(fields);
    return mainQuery.find();
}

export const deleteStudent = async (deleteObj) => {

    return deleteObj.destroy()

}

export const removeAllStudent = async (schoolId) => {

    let data = { className: "Students_" + schoolId }
    let result = await Parse.Cloud.run("removeAllStudent", data)
    return result

}

export const importStudent = async (StudentData, schoolId, hasQr = false) => {
    let qr = hasQr ? [] : await Parse.Cloud.run("genrateBulkQrCode", { len: StudentData.length })
    let schoolInfo = JSON.parse(JSON.stringify(await getSchoolById(schoolId)))
    var Student_Object = Parse.Object.extend("Students_" + schoolId);
    let data = StudentData
    var studentsToImport = [];
    let classesToimport = data

    // School Class update 
    let schoolClass = schoolInfo?.class ? schoolInfo?.class : []
    const unique = [...new Set(classesToimport.map(item => item.class))]
    unique.map(el => {
        if (!schoolClass.includes(el?.toString())) {
            if (el || el != null || el != "")
                schoolClass = [el?.toString(), ...schoolClass]
        }
    })
    let updatedData = { schoolId, newList: schoolClass }
    await updateSchoolClass(updatedData)

    // Student Import funtion
    await Promise.all(data.map(async (item, index) => {
        var studentEntry = new Student_Object();
        if (!hasQr) {
            studentEntry.set("qr", qr[index])
        }
        Object.keys(item).map(key =>

            studentEntry.set(key, item[key].toString())
        )

        studentsToImport.push(studentEntry);
    }))

    return Parse.Object.saveAll(studentsToImport);


}

export const importPhoto = async (photo, photono, schoolId) => {
    let data = { photo, photono, schoolId }
    // let result = await Parse.Cloud.run("importImage", data)
    return Parse.Cloud.run("importImage", data)


    // // const mainQuery = new Parse.Query("Students_" + schoolId);
    // // mainQuery.equalTo("photono", photono);
    // // let findStudent = await mainQuery.first();
    // // const studentId = JSON.parse(JSON.stringify(findStudent)).objectId;
    // // const updateEntry = new Parse.Object("Students_" + schoolId);
    // // if (studentId) {
    // //     updateEntry.set("objectId", studentId)
    //     if (photo) {
    //         const base64 = photo
    //         const parseFile = new Parse.File(photono, { base64: base64 });
    //         let newfile = await parseFile.save()
    //         updateEntry.set("photo", newfile);
    //     }

    // //     return updateEntry.save()
    // // } else {
    // //     throw "sdasda"
    // // }
}

export const removeStudentPhoto = async (data) => {
    const { className, objectId } = data
    // const mainQuery = new Parse.Query(className);
    // mainQuery.equalTo("objectId", objectId)
    // mainQuery.select("photo")
    // let fileToDelete = await mainQuery.first()
    let result = await Parse.Cloud.run("removeFile", data)
    const updateEntry = new Parse.Object(className);
    updateEntry.set("objectId", objectId)
    updateEntry.set("photono", "-1")

    return updateEntry.save()

}
