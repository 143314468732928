import React, { Component, useState, useEffect } from 'react';
import { Container, Row } from "reactstrap";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import data from './data.json';
import SchoolList from './SchoolList';


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const StarterPage = ({ title }) => {
    const [breadcrumbItems] = useState([
        { title: "Schools", link: "/schools" },
        { title: "Dashboard", link: "#" },
    ]);




    useEffect(() => {
       
    }, [breadcrumbItems, title]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Schools" breadcrumbItems={breadcrumbItems} />


                    <SchoolList />

                </Container>
            </div>
        </React.Fragment>
    );
}

// class StarterPage1 extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             breadcrumbItems: [
//                 { title: "Colorbenz", link: "#" },
//                 { title: "Dashboard", link: "#" },
//             ],
//         }
//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="page-content">
//                     <Container fluid>

//                         <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />

//                     </Container>
//                 </div>
//             </React.Fragment>
//         );
//     }
// }

export default StarterPage;