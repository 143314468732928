import React, { Component, useState, useEffect } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, CardBody, Card } from "reactstrap";
import classnames from 'classnames';



//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Link, useHistory, useParams } from "react-router-dom";
import { getSchoolById } from '../../services/schools';
import { storage } from '../../utils/utils';
import AttendanceDaily from './AttendanceDaily';
import AttendanceMonthly from './AttendanceMonthly';


const ReportsBase = ({ title }) => {
    const { id } = useParams()

    const [schoolInfo, setSchoolInfo] = useState()
    const [activeTab, setActiveTab] = useState("1")
    const [breadcrumbItems] = useState([

    ]);



    useEffect(() => {

    }, [breadcrumbItems, title]);

    useEffect(() => {
        let schoolData = storage.getSchool()
        console.log("schoolData", schoolData)
        setSchoolInfo(schoolData)
    }, [])

    useEffect(() => {
     

    }, [activeTab])


    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Reports" breadcrumbItems={breadcrumbItems} />


                    <Row>
                        <Col lg={12}>
                            <Card>
                                <h5 className={classnames({ active: activeTab === '-11' }, " p-3 font-weight-bold text-uppercase")}

                                >   <i className="ri-community-line  align-bottom mr-1"></i> {schoolInfo?.name}</h5>
                                <CardBody className="pt-0">

                                    <Nav tabs className="nav-tabs-custom mb-4">

                                        <NavItem>
                                            <NavLink onClick={() => { toggleTab('1'); }} className={classnames({ active: activeTab === '1' }, "font-weight-bold p-3")}>Attendance Reports</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink onClick={() => { toggleTab('2'); }} className={classnames({ active: activeTab === '2' }, "p-3 font-weight-bold")}>Monthly Attendance Reports</NavLink>
                                        </NavItem>




                                    </Nav>
                                    {activeTab == 1 && schoolInfo && < AttendanceDaily schoolInfo={schoolInfo} />}
                                    {activeTab == 2 && schoolInfo && < AttendanceMonthly schoolInfo={schoolInfo} />}

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
}



export default ReportsBase;