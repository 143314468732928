import Parse from 'parse';
import { getSchoolById } from '../services/schools';

import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';


const header = ["studentname", "fathername", "surname", "mothername", "address", "class", "bg", "aadhar", "phone1", "phone2", "dob", "qr", "rollno", "grno", "isOrdered", "photoNew"]

export const storage = {
    getSchool: () => JSON.parse(window.localStorage.getItem("currentSchool")),
    setSchool: (schoolData) =>
        window.localStorage.setItem("currentSchool", JSON.stringify(schoolData)),
    clearSchool: () => window.localStorage.removeItem("currentSchool")
};


export const isSuperAdmin = () => {
    const user = Parse.User.current();
    if (user.get("userType") == 1) {
        return true
    }
    else {
        return false
    }
}


export const getOrderStatus = (data) => {

    switch (data) {
        case 0:
            return "Pending"

        case 1:
            return "InProgress"

        case 2:
            return "Complete"

        default:
            return "Pending"
    }
}


export const getUserRole = (roleId) => {

    switch (roleId) {
        case 1:
            return "Super Admin"

        case 2:
            return "Operator"

        case 3:
            return "School Admin"

        case 4:
            return "School Staff"

        default:
            return "N/A"
    }
}



const generateUniqueId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
}


const convertDataUpperCase = (obj) => {
    for (let prop in obj) {
     
        if (typeof obj[prop] === 'string' && prop !== "qr" && prop !="photoNew"  ) {
            obj[prop] = obj[prop].toUpperCase();
        }
        if (typeof obj[prop] === 'object') {
            convertDataUpperCase(obj[prop]);
        }
    }
    return obj;
}



const getExcelData = (ExcelData) => {

    // function convertDataUpperCase(obj) {
    //     for (let prop in obj) {
    //         if (typeof obj[prop] === 'string') {
    //             obj[prop] = obj[prop].toUpperCase();
    //         }
    //         if (typeof obj[prop] === 'object') {
    //             convertDataUpperCase(obj[prop]);
    //         }
    //     }
    //     return obj;
    // }


    // let newExcelData = ExcelData.map(item => {
    //     let studentname = item.studentname ? item.studentname : ""
    //     let fathername = item.fathername ? item.fathername : ""
    //     let surname = item.surname ? item.surname : ""
    //     return {
    //         ...item,
    //         fullname: surname + "" + studentname + " " + fathername,
    //     };
    // });

    // newExcelData = convertDataUpperCase(newExcelData)

    // console.log("ExcelData final", newExcelData)
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const header =  ['photoNew','class', 'grno','dob','surname', 'studentname','fullname', 'fathername', 'mothername', 'phone1', 'phone2', 'address', 'bg', 'aadhar', 'qr'];
     
    const ws = XLSX.utils.json_to_sheet(ExcelData, { header });
    const wb = { Sheets: { 'Sheet1': ws }, SheetNames: ['Sheet1'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const edata = new Blob([excelBuffer], { type: fileType });
    return edata
}

const getFileNameFromUrl = (element, isWithExtention = true) => {
    let urlArr = element.replace(/^.*[\\\/]/, '')
    let tmpfilename = urlArr.split('_')
    let filename = tmpfilename[tmpfilename.length - 1];
    let testFilename = filename.split(".")
    if (testFilename[0] === "") {
        filename = urlArr
    }
    if (isWithExtention) {
        return filename
    } else {

        return filename.substring(0, filename.lastIndexOf('.'))
    }

}

export const exportZipData = async (data, excelFilename = "Final", zipfilename = generateUniqueId()) => {

    const zip = new JSZip();
    const zipFilename = zipfilename + ".zip";
    let ExcelData = JSON.parse(JSON.stringify(data))
    await Promise.all(ExcelData.map(async (el) => {
        let url = el.photo?.url

        if (url) {
            let filename = getFileNameFromUrl(el.photo?.url)

            let final = filename.substring(0, filename.lastIndexOf('.')) || filename


            await JSZipUtils.getBinaryContent(url).then(res => {

                zip.file(filename, res, { binary: true });

            }).catch(err => {
                console.log("err", err.message)
                let msg = err.message
                let url = msg.match(/\bhttps?:\/\/\S+/gi)[0];
                let errFile = getFileNameFromUrl(url, false)
                console.log("final", final, errFile)
                if (final === errFile) {
                    final = undefined
                }
            })

            el.photoNew = final

        }
        // let studentname = el.studentname ? el.studentname : ""
        // let fathername = el.fathername ? el.fathername : ""
        // let surname = el.surname ? el.surname : ""
        // el.fullname = surname + "" + studentname + " " + fathername

        delete el.photo
        delete el.objectId
        delete el.photono
        delete el.createdAt
        delete el.updatedAt
        convertDataUpperCase(el)

    })
    )

    zip.file(excelFilename + ".xlsx", getExcelData(ExcelData), { binary: true });
    return zip.generateAsync({ type: 'blob' }).then(function (content) {
        FileSaver.saveAs(content, zipFilename);

    });


    // urls.forEach(async function (url) {

    //     const urlArr = url?.split('/');

    //     const tmpFiles = urlArr[urlArr.length - 1];
    //     const tmpfilename = tmpFiles.split('_')
    //     const filename = tmpfilename[tmpfilename.length - 1];
    //     try {
    //         const file = await JSZipUtils.getBinaryContent(url)
    //         zip.file(filename, file, { binary: true });
    //         count++;
    //         if (count === urls.length) {
    //             return zip.generateAsync({ type: 'blob' }).then(function (content) {
    //                 FileSaver.saveAs(content, zipFilename);

    //             });
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // });
}


