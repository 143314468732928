import React, { useEffect, useState } from 'react';
import { Col, Row, Input, Button, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { toast } from 'react-toastify';
import { getSchools } from '../../services/schools';
import { Link, useHistory } from "react-router-dom";
import Switch from "react-switch";
import { exportZipData, storage } from '../../utils/utils';
import { deleteStudent, getStudents, importPhoto, importStudent, removeAllStudent, removeStudentPhoto, updateStudent } from '../../services/students';
import { ColumnGroup } from 'primereact/columngroup';
import { Dialog } from 'primereact/dialog';
import NotificationsService from '../../components/Common/toaster';
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import moment from 'moment'
import SampleExcel from '../../assets/SAMPLE.xlsx';


const StudentList = ({ schoolInfo, ...props }) => {
  let typingTimeout = null;
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';


  const history = useHistory()
  const [present, setPresent] = useState(false)
  const [absent, setAbsent] = useState(false)

  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [currentStudent, setCurrentStudent] = useState({});
  const [importLoader, setImportLoader] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileInfo, setFileInfo] = useState();
  const [importModal, setImportModal] = useState(false);
  const [failedFilesData, setFailedFilesData] = useState([]);
  const [isRemovingStudentImage, setIsRemovingStudentImage] = useState(false);

  const [lazyParams, setLazyParams] = useState({
    searchText: "",
    first: 0,
    rows: 5,
    page: 1,
    sortField: "",
    sortOrder: 0,
    selectedClass: -1
  });

  // React=query clinet to refeatch data, etc.. oprations
  const queryClient = useQueryClient()

  //get Student Queries
  const { data: results, refetch: reload, isLoading, isFetching } = useQuery(['Students', lazyParams, schoolInfo.objectId], () => getStudents(lazyParams, schoolInfo.objectId), {
    keepPreviousData: true,
    // staleTime: 10000, // only eligible to refetch after 10 seconds
    onError: (error) => {
      toast.error(error.message);

    },
  })

  // Mutations to delete student
  const { mutateAsync: deleteMuted, isLoading: isDeleting } = useMutation(deleteStudent, {

    onSuccess: (data) => {
      debugger;
      const previousValue = queryClient.getQueryData('Students', { exact: false });
      const updatedValue = [...previousValue.results];
      const removeDeleted = updatedValue.filter(eachValue => eachValue.id !== data.id);
      previousValue.results = removeDeleted
      queryClient.setQueryData("Students", previousValue);
      NotificationsService.success("User removed successfully")
      // queryClient.invalidateQueries('users')

    },
    onError: (error) => {
      NotificationsService.error(error.message);

    }
  })

  // Mutations to delete student photo from server
  const { mutateAsync: removeStudentImageMuted, isLoading: isImageDeleting } = useMutation(removeStudentPhoto, {

    onSuccess: (data) => {
      const previousValue = queryClient.getQueryData(['Students', lazyParams, schoolInfo.objectId], { exact: false });
      const updatedValue = [...previousValue.results];

      const removeDeleted = updatedValue.map((el) => {
        if (el.id == data.id) {
          // let item = el.get("photo")
          // item._url = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
          el.set("photo", "")

        }
        return el
      });


      previousValue.results = removeDeleted
      queryClient.setQueryData("Students", previousValue);
      NotificationsService.success("Student photo removed successfully")
      // queryClient.invalidateQueries('Students')

    },
    onError: (error) => {
      NotificationsService.error(error.message);

    }
  })

  const onPage = (event) => {
    let data = event
    data.page = event.page + 1
    let _lazyParams = { ...lazyParams, ...data };
    setLazyParams(_lazyParams);
  }

  const onSearch = (text) => {
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {

      let _lazyParams = { ...lazyParams, searchText: text };
      setLazyParams(_lazyParams);
    }, 475);

  }

  const onClassChange = (text) => {

    let _lazyParams = { ...lazyParams, selectedClass: text };
    setLazyParams(_lazyParams);


  }

  const confirmDeleteStudent = (studentData) => {
    setCurrentStudent(studentData);
    setDeleteUserDialog(true);
  }


  const actionBodyTemplate = (rowData) => {
    let data = JSON.parse(JSON.stringify(rowData))
    let newData = {
      objectId: data.objectId,
      studentname: data.studentname,
      fathername: data.fathername,
      surname: data.surname,
      mothername: data.mothername,
      class: data.class,
      phone1: data.phone1,
      phone2: data.phone2,
      dob: data.dob,
      bg: data.bg,
      address: data.address,
      aadhar: data.aadhar,
      ...(data.photo) && { photo: data.photo },
      grno: data.grno,
      rfid: data.rfid,
      qr: data.qr
    }


    return <React.Fragment>
      <div className="d-flex">

        <span onClick={() => {
          history.push(
            {
              pathname: `/schools/${storage.getSchool().objectId}/addstudent`,
              state: { detail: newData }
            }


          )
        }} className="mr-3 text-primary" id="edit1" style={{ cursor: "pointer" }}><i className="ri-pencil-line font-size-18"></i></span>
        <UncontrolledTooltip placement="auto" target="edit1">
          Edit
        </UncontrolledTooltip >

        <span onClick={() => confirmDeleteStudent(rowData)} className="text-danger" id="delete1"
          style={{ cursor: "pointer" }}><i className="ri-delete-bin-6-line font-size-18"></i></span>
        <UncontrolledTooltip placement="auto" target="delete1">
          Delete
        </UncontrolledTooltip >


      </div>
    </React.Fragment>
    // <button className="btn-outline-primary btn-rounded waves-effect add-btn float-right ml-auto float-right"
    // onClick={() => { window.$('#Create').modal('show') }}> <i class="far fa-edit"></i> </button>;
  }

  const Offsymbol = (text) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {text}
      </div>
    );
  };

  const OnSymbol = (text) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingLeft: "15px"
        }}
      >
        {text}
      </div>
    );
  };


  const imageBodyTemplate = (rowData, { rowIndex }) => {
    let photoNo = rowData.get("photono")
    let noPhotoUrl = "'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'"
    return <div className='text-center' key={"imgDiv" + rowIndex}>
      <img
        src={rowData.get("photo") ? rowData.get("photo")?._url : noPhotoUrl}
        onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
        alt={"Sorry"}
        width={50}
        height={50}
        className="product-image mb-1" />

      <button
        color="danger"
        size="sm"
        id={"removeImage" + rowIndex}
        key={"imgrm" + rowIndex}
        disabled={isImageDeleting || photoNo == "-1" || (!photoNo) ? true : false}
        className="waves-effect waves-light"
        style={{ background: "transparent", border: "none", cursor: "pointer" }}
        onClick={() => {
          setIsRemovingStudentImage(true)
          confirmDeleteStudent(rowData)
        }}
      >

        <i className="ri-close-circle-line"></i>
      </button>

      <UncontrolledTooltip placement="auto" key={rowIndex} target={"removeImage" + rowIndex}>
        Remove Photo
      </UncontrolledTooltip >
    </div>
  }

  const fullNameTemplate = (rowData) => {
    let fName = rowData.get("studentname") ? rowData.get("studentname") : ""
    let mName = rowData.get("fathername") ? rowData.get("fathername") : "";
    let lName = rowData.get("surname") ? rowData.get("surname") : ""
    return <React.Fragment>
      <div>
        {fName + " " + mName + " " + lName}
      </div>
    </React.Fragment>
  }

  const hideDeleteUserDialog = () => {
    setDeleteUserDialog(false);
    setCurrentStudent({})
    isRemovingStudentImage && setIsRemovingStudentImage(false)
  }


  const deleteUserData = async () => {

    hideDeleteUserDialog();
    if (isRemovingStudentImage) {
      let className = "Students_" + schoolInfo.objectId
      let objectId = currentStudent.id
      let finalDataToPost = { className, objectId }
      removeStudentImageMuted(finalDataToPost)

    } else {
      await deleteMuted(currentStudent)
    }
  }

  const deleteAllStudentData = () => {
    removeAllStudent(storage.getSchool().objectId).then((res) => {
      NotificationsService.success("Removed all student")
      queryClient.invalidateQueries("Students")
    }

    ).catch((err => {
      NotificationsService.error(err.message)
    }))
    setDeleteUserDialog(false);
  }


  const deleteUserDialogFooter = () => (
    <React.Fragment>
      <Button type="button" color="light" onClick={hideDeleteUserDialog} >No</Button>
      <Button type="button" color="primary" onClick={Object.entries(currentStudent).length == 0 ? deleteAllStudentData : deleteUserData}>Yes</Button>
    </React.Fragment>
  );

  const readUploadFile = async (e) => {
    // Const Config to import student
    let arrayToCheck = ['photono', 'grno', 'class', 'dob', 'studentname', 'fathername', 'surname', 'address', 'phone1', 'phone2', 'mothername', 'bg', 'aadhar', 'rollno']
    let hasQr = false
    if (hasQr) {
      arrayToCheck.push("qr")
    }

    setImportLoader(true)
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        // const workbookHeaders = XLSX.readFile(data, { sheetRows: 1 });
        // const columnsArray = XLSX.utils.sheet_to_json(workbookHeaders.Sheets["data"], { header: 1 })[0];
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        // const json = XLSX.utils.sheet_to_json(worksheet, { defval: "" });
        // const json = XLSX.utils.sheet_to_json(worksheet,{blankrows: true, defval: ''});
        const columnsArray = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
        let rejectedField = []
        columnsArray.forEach(el => {
          if (!arrayToCheck.includes(el)) {
            rejectedField.push(el)
          }
        })
        if (rejectedField.length > 0) {
          NotificationsService.error("Please download sample to check your excel. These fields are not allowed : " + rejectedField.toString())
          setImportLoader(false)

          return;
        }
        const json = XLSX.utils.sheet_to_json(worksheet);

        importStudent(json, storage.getSchool().objectId, hasQr)
          .then(res => {
            NotificationsService.success("Yes, Import process done !!!")
            queryClient.invalidateQueries("Students");
            setImportLoader(false)
          })
          .catch(err => { NotificationsService.error(err.message) })
        setImportLoader(false)
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }



  const handelExport = async () => {

    let _lazyParams = { ...lazyParams };
    _lazyParams.first = 0
    _lazyParams.rows = results?.count


    setExportLoader(true)
    let tmpStudentData = await getStudents(_lazyParams, schoolInfo.objectId)
    let data = tmpStudentData?.results
    let excelFilename = schoolInfo.name
    await exportZipData(data, excelFilename, excelFilename)
    setExportLoader(false)

  }


  const handelImportPhoto = async (e) => {
    setImportLoader(true)

    let fileData = e.target.files
    let schoolId = storage.getSchool().objectId
    let failedFiles = []
    for (let index = 0; index < fileData.length; index++) {
      const element = fileData[index];
      const fileSize = element.size / 1024 / 1024 // convert file size in Mb
      if (fileSize > 0.5) {
        failedFiles.push(element)
        continue;
      }
      const photoNo = element.name.split('.').slice(0, -1).join('.')
      let idCardBase64 = await toBase64(element)
      await importPhoto(idCardBase64, photoNo, schoolId).catch(err => {
        NotificationsService.error(err.message)

      })
    }

    let FailedData = []
    failedFiles.map(el => FailedData.push({ filename: el.name, "size(Mb)": (el.size / 1024 / 1024) }))
    setFailedFilesData(FailedData)
    setImportLoader(false)
    queryClient.invalidateQueries("Students")

  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const exportToCSV = (csvData, fileName) => {

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }


  return (
    <React.Fragment>


      <Row className='mr-0 mb-2'>
        <Col md={4} style={{ textAlign: "left" }} className="d-flex"  >


          <div className="search-box ml-0 mt-2" style={{ width: "70%" }}>
            <div className="position-relative">
              <Input type="text" className="form-control-sm rounded" placeholder="Search..." onChange={(e) => onSearch(e.target.value)} />
              <i className="mdi mdi-magnify search-icon" style={{ lineHeight: "30px" }}></i>
            </div>
          </div>


        </Col>
        <Col md={8} style={{ textAlign: "end" }} className="p-0">
          {<select className="custom-select custom-select-sm  ml-2 mt-2" style={{ width: "20%" }}
            onChange={(e) => { onClassChange(e.target.value) }}>
            <option defaultValue value={-1}>Class-All</option>
            {schoolInfo.class.map(item => <option key={item} value={item}>{item}</option>)}
          </select>
          }
          <Button
            onClick={() => { setDeleteUserDialog(true); }}
            disabled={isLoading || results?.count == 0 ? true : false}
            color="danger"
            size="sm"
            className="waves-effect waves-light ml-2 mt-2"
          >
            <i className="ri-delete-bin-6-line align-bottom mr-1"></i> Remove all Student
          </Button>
          <Button
            onClick={() => { history.push(`/schools/${storage.getSchool().objectId}/addstudent`) }}
            color="success"
            size="sm"
            className="waves-effect waves-light ml-2 mt-2"
          >
            <i className="ri-user-line align-bottom mr-1"></i> Add Student
          </Button>

          <Button
            onClick={() => { handelExport() }}
            disabled={exportLoader || isLoading || results?.count == 0 ? true : false}
            color="success"
            size="sm"
            className="waves-effect waves-light ml-2 mt-2"
          >
            <i className="ri-download-cloud-line align-bottom mr-1 text-capital"></i>{exportLoader && "Please Wait Import In "} Export Data
          </Button>
          <Button
            color="primary"
            size="sm"
            onClick={() => setImportModal(true)}
            className="waves-effect waves-light ml-2 mt-2"
          >
            <i className="ri-upload-cloud-2-line align-bottom mr-1"></i> Import
          </Button>
          <Modal
            isOpen={importModal}
            toggle={() => { setImportModal(!importModal) }}
            backdrop="static"
          >
            <ModalHeader toggle={() => { setImportModal(false); setFailedFilesData([]) }}>
              Import student
            </ModalHeader>
            <ModalBody>
              <h5>Step-1</h5>
              <p className='mb-2'>First import excel file then only upload photo(s).</p>
              <Link to={SampleExcel} target="_blank" download> Click here to download sample </Link> <br></br>

              <label
                htmlFor={importLoader ? "xxxx" : 'import-student'}
                color="primary"
                size="sm"
                className={`waves-effect waves-light ml-2 mt-2 btn btn-primary btn-sm mb-0 ${importLoader ? "disabled" : ""}`}
              >
                {importLoader ? "Please Wait..." : "Import Student"}  <i className="ri-file-excel-2-line align-bottom ml-1"></i>
              </label>
              <input id="import-student"
                type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={readUploadFile}
                onClick={(event) => {
                  event.target.value = null
                }} />
              <hr></hr>
              <h5>Step-2</h5>
              <p>Import photo(s) now</p>
              <label
                htmlFor={isLoading || results?.count == 0 ? "true" : 'import-photo'}
                color="primary"
                size="sm"
                // onClick={() => setImportModal(true)}
                className={`waves-effect waves-light ml-2 mt-2 btn btn-primary btn-sm mb-0 ${isLoading || importLoader || results?.count == 0 ? "disabled" : ""}`}
              >
                {importLoader ? "Please Wait..." : "Import Photo"} <i className="ri-image-add-line align-bottom ml-1"></i>
              </label>
              <input id="import-photo" type="file" accept="image/*" multiple onChange={handelImportPhoto}
                onClick={(event) => {
                  event.target.value = null
                }} />
              {failedFilesData.length > 0 && <Link className='ml-3' to="#" onClick={() => { exportToCSV(failedFilesData, "FailedToUpload") }} > Download Failed files information</Link>
              }
            </ModalBody>

          </Modal>
        </Col>


      </Row>

      <DataTable
        lazy
        value={results?.results}
        paginator
        totalRecords={results?.count}
        responsiveLayout="scroll"
        stripedRows
        showGridlines
        size="small"
        loading={isFetching}
        onPage={onPage}
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown small"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        rows={lazyParams.rows}
        first={lazyParams.first}
        rowsPerPageOptions={[5, 10, 25]}


      >
        <Column field="image" header="Photo" body={imageBodyTemplate} headerStyle={{ width: '4rem' }} ></Column>
        <Column body={fullNameTemplate} header="Name" headerStyle={{ width: '20rem' }} ></Column>
        <Column body={(el) => el.get("class")} header="Class"></Column>
        <Column body={(el) => el.get("phone1")} header="Phone1"></Column>
        <Column body={(el) => el.get("phone2")} header="Phone2"></Column>
        <Column body={(el) => el.get("dob")} header="D.O.B."></Column>
        <Column body={(el) => el.get("bg")} header="B.G."></Column>
        <Column body={(el) => el.get("grno")} header="G.R."></Column>
        <Column body={(el) => el.get("aadhar")} header="Aadhar No."></Column>
        <Column field="address" body={(el) => el.get("address")} header="Address"></Column>
        <Column headerStyle={{ width: '4rem' }} header="Action" body={actionBodyTemplate}></Column>


      </DataTable>

      <Dialog visible={deleteUserDialog} header="Confirm" modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-2 " style={{ fontSize: '2rem' }} />

          {
            Object.entries(currentStudent).length == 0 ?
              " Are you sure you want to remove all entries ?" :
              <span>Are you sure you want to delete {isRemovingStudentImage && "photo of"} student - <b>{currentStudent.get("studentname")}</b> {isRemovingStudentImage && "permanently"} ?</span>}
        </div>
      </Dialog>


    </React.Fragment>
  );
}

export default StudentList; 