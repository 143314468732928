import React, { useEffect, useState, useRef } from 'react';
import { Col, Row, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Card, CardBody, Input, Button, FormGroup, Label, ButtonGroup } from "reactstrap";

import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'

import { Calendar } from 'primereact/calendar';
import moment from 'moment'

import EditableListBox from '../../components/Common/EditableListBox';
import NotificationsService from '../../components/Common/toaster';
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { saveSchool } from '../../services/schools';
import { useHistory, useLocation } from 'react-router-dom';

import { InputMask } from 'primereact/inputmask';
import faceImage from '../../assets/images/face.png'
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { saveStudent, updateStudent } from '../../services/students';
import { storage } from '../../utils/utils';
import * as XLSX from "xlsx";
const schema = yup.object({
  studentname: yup.string().required('This field is required.'),
  phone1: yup.number().positive().transform((v) => (v === '' || Number.isNaN(v) ? null : v)).nullable(),
  phone2: yup.number().positive().transform((v) => (v === '' || Number.isNaN(v) ? null : v)).nullable(),
  aadhar: yup.number().positive().transform((v) => (v === '' || Number.isNaN(v) ? null : v)).nullable(),
  grno: yup.number().positive().transform((v) => (v === '' || Number.isNaN(v) ? null : v)).nullable(),
  rfid: yup.number().positive().transform((v) => (v === '' || Number.isNaN(v) ? null : v)).nullable(),
  rollno: yup.number().positive().transform((v) => (v === '' || Number.isNaN(v) ? null : v)).nullable(),

  // company: yup.string().required('This field is required.'),
  // email: yup.string().email('Please enter valid email').required('This field is required.'),

}).required();


const AddStudentForm = ({ title }) => {


  const schoolClass = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
  const schoolDivison = ["A", "B", "C", "D"]
  const bg = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"]
  const house = ["Blue", "Green", "Red", "Yellow"]
  const days = [
    {
      day: "monday", from: 1651198217115, to: 1651216337116

    },
    {
      day: "tuesday", from: 1651198217115, to: 1651216337116

    },
    {
      day: "wednesday", from: 1651198217115, to: 1651216337116

    },
    {
      day: "thursday", from: 1651198217115, to: 1651216337116

    },
    {
      day: "friday", from: 1651198217115, to: 1651216337116

    },
    {
      day: "saturday", from: 1651198217115, to: 1651216337116

    },
    {
      day: "sunday", from: 1651198217115, to: 1651216337116

    },
  ]

  const history = useHistory()
  const location = useLocation();

  const [selectedImage, setSelectedImage] = useState();
  const [studentData, setStudentData] = useState(location.state?.detail);
  const [studentImage, setstudentImage] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);


  useEffect(() => {
    console.log("location.pathname", location.pathname); // result: '/secondpage'
    console.log("location.state.detail", location.state?.detail); // result: 'some_value'
    if (location.state?.detail) {
      let data = location.state?.detail
      // delete data.id
      let isSettingFormValueOnEdit = true
      resetFormValues(data, isSettingFormValueOnEdit)
    }
  }, [location]);



  // HookForm Initialization
  const { register, handleSubmit, formState: { errors }, reset, resetField, setValue, control } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema)
  });

  const submitSchoolData = async (data, e) => {
    let idCardBase64 = selectedImage && await toBase64(selectedImage)
    delete data.photo
    let updatedData = { ...(idCardBase64) && { photo: idCardBase64 }, schoolId: storage.getSchool().objectId, ...data }
    console.log("first", updatedData)




    updateStudent(updatedData, isEditMode).then(
      NotificationsService.success("Done , Entry updated !!! ")
    ).catch((err) => {
      NotificationsService.error(err.message)
    })


    !isEditMode && resetFormValues(data)

  }


  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }

  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const resetFormValues = (data, isEdit = false) => {
    var emptyFormValues = {}
    Object.keys(data).map((key) => {

      emptyFormValues[key] = isEdit ? data[key] : ""

    }
    )
    if (!isEdit) {
      emptyFormValues.classes = "1"
      emptyFormValues.division = "A"
      emptyFormValues.bg = "-1"
      // emptyFormValues.house = "-1"
      removeSelectedImage();
    } else {
      setstudentImage(data.photo?.url)
      setIsEditMode(true)
    }
    reset(emptyFormValues);
  }




  return (
    <React.Fragment>

      <Col lg={12}>
        <Card>
          <CardBody>
            <Row className='mr-0 mb-2'>
              <Col md={8} className="">

                <h4 className="card-title">Student Details</h4>
                <hr className='m-0' />
                <Row>
                  <Col md={4} >
                    <FormGroup>

                      <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Class</Label>
                      <Col md={12}>
                        <select className="custom-select custom-select-sm" id="class"
                          {...register("class")}>
                          {/* <option defaultValue>Class-All</option> */}
                          {/* <option value="1" defaultValue>1</option>
                          <option value="2">2</option>

                          <option value="3">3</option> */}
                          {storage.getSchool().class.map((item, index) => <option key={index} value={item}>{item}</option>)}

                        </select>
                      </Col>

                    </FormGroup>
                  </Col>

                </Row>
                <Row>
                  <Col md={3} >
                    <FormGroup>
                      <Label htmlFor="example-text-input" className="col-md-6 col-form-label">Surname</Label>
                      <Col md={12}>
                        <Controller
                          name="surname"
                          control={control}

                          render={({ field }) => <Input className="form-control" type="text" id="surname" {...field} />}
                        />
                      </Col>
                    </FormGroup>

                  </Col>
                  <Col md={3} >

                    <FormGroup>
                      <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Studentname</Label>
                      <Col md={12}>
                        <Controller
                          name="studentname"
                          control={control}
                          render={({ field }) => <Input className="form-control" type="text" id="studentname" {...field} />}
                        />
                        {<div className="error mt-1">{errors.studentname?.message}</div>}
                      </Col>
                    </FormGroup>

                  </Col>
                  <Col md={3} >

                    <FormGroup>
                      <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Fathername</Label>
                      <Col md={12}>
                        <Controller
                          name="fathername"
                          control={control}
                          render={({ field }) => <Input className="form-control" type="text" id="fathername" {...field} />}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={3} >

                    <FormGroup>
                      <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Mothername</Label>
                      <Col md={12}>
                        <Controller
                          name="mothername"
                          control={control}
                          render={({ field }) => <Input className="form-control" type="text" id="mothername" {...field} />}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} >
                    <FormGroup>
                      <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Address</Label>
                      <Col md={12}>
                        <Controller
                          name="address"
                          control={control}
                          render={({ field }) => <Input className="form-control" type="textarea" id="address" {...field} />}
                        />

                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={3} >
                    <FormGroup>
                      <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Phone1</Label>
                      <Col md={12}>
                        <Controller
                          name="phone1"
                          control={control}
                          render={({ field }) => <Input className="form-control" type="number" id="phone1" {...field} />}
                        />
                        {<div className="error mt-1">{errors.phone1?.message}</div>}

                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={3} >
                    <FormGroup>
                      <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Phone2</Label>
                      <Col md={12}>
                        <Controller
                          name="phone2"
                          control={control}
                          render={({ field }) => <Input className="form-control" type="number" id="phone2" {...field} />}
                        />
                        {<div className="error mt-1">{errors.phone2?.message}</div>}

                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={4} >
                    <FormGroup>
                      <Label htmlFor="example-text-input" className="col-md-12 col-form-label">D.O.B.</Label>
                      <Col md={12}>
                        <Controller
                          name="dob"
                          control={control}

                          render={({ field }) =>

                            <InputMask className="form-control" id="dob" mask="99/99/9999" {...field} placeholder="dd/mm/yyyy" slotChar="dd/mm/yyyy" ></InputMask>
                          }
                        />

                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={4} >
                    <FormGroup>
                      <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Blood Group</Label>
                      <Col md={12}>
                        <select name='bg' id="bg" {...register("bg")}
                          className="custom-select custom-select">
                          {/* <option defaultValue>Class-All</option> */}
                          <option value="-1" defaultValue>Please Select</option>
                          {bg.map(item => <option value={item} >{item}</option>)}

                        </select>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={4} >
                    <FormGroup>
                      <Label htmlFor="rfid" className="col-md-12 col-form-label">Roll No.</Label>
                      <Col md={12}>
                        <Controller
                          name="rollno"
                          control={control}
                          render={({ field }) => <Input className="form-control" type="number" id="rollno" {...field} />}
                        />
                        {<div className="error mt-1">{errors.rollno?.message}</div>}

                      </Col>
                    </FormGroup>
                  </Col>

                </Row>

                <Row>
                  <Col md={4} >
                    <FormGroup>
                      <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Aadhar card number</Label>
                      <Col md={12}>
                        <Controller
                          name="aadhar"
                          control={control}
                          render={({ field }) => <Input className="form-control" type="number" id="aadhar" {...field} />}
                        />
                        {<div className="error mt-1">{errors.aadhar?.message}</div>}

                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={4} >
                    <FormGroup>
                      <Label htmlFor="example-text-input" className="col-md-12 col-form-label">Gr. No</Label>
                      <Col md={12}>
                        <Controller
                          name="grno"
                          control={control}
                          render={({ field }) => <Input className="form-control" type="number" id="grno" {...field} />}
                        />
                        {<div className="error mt-1">{errors.grno?.message}</div>}

                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md={4} >
                    <FormGroup>
                      <Label htmlFor="rfid" className="col-md-12 col-form-label">Rfid No.</Label>
                      <Col md={12}>
                        <Controller
                          name="rfid"
                          control={control}
                          render={({ field }) => <Input className="form-control" type="number" id="rfid" {...field} />}
                        />
                        {<div className="error mt-1">{errors.rfid?.message}</div>}

                      </Col>
                    </FormGroup>
                  </Col>
                </Row>

              </Col>
              <Col md={4} className="">
                <div className="flex justify-content-center">
                  <h4 className="card-title">Photo Upload</h4>
                  <hr className='m-0' />

                  {/* <div className="custom-control custom-radio mb-3">
                    <Input type="radio" id="customRadio1" name="customRadio" className="custom-control-input" />
                    <Label className="custom-control-label" htmlFor="customRadio1">Monday</Label>
                  </div> */}
                  <Col md={12} >
                    {(
                      <div className='text-center p-2'>
                        <div className={"user-img align-self-center mr-3 "}>
                          <img
                            onError={(e) => e.target.src = faceImage}
                            src={selectedImage ? URL.createObjectURL(selectedImage) : studentImage ? studentImage : faceImage}
                            className="border avatar-xl" alt="" />
                          <span className="user-status"></span>
                        </div>

                        {/* <Button color="danger" className="waves-effect waves-light ml-1" onClick={removeSelectedImage} >
                          <i className="ri-close-circle-line align-bottom"></i>
                        </Button> */}

                        <label htmlFor='file-upload' className="btn-rounded waves-effect mr-1 mt-2 btn btn-info">
                          Upload Photo
                        </label>
                        {selectedImage && <label className="btn-rounded waves-effect waves-light mr-1 mt-2 btn btn-danger" onClick={removeSelectedImage}>
                          Clear
                        </label>}
                        <input id="file-upload" type="file" onChange={imageChange}
                          onClick={(event) => {
                            event.target.value = null
                          }} />
                      </div>
                    )}


                  </Col>
                </div>
              </Col>
            </Row>
            <hr />
            <Row className='pr-3 justify-content-end'>
              <FormGroup >
                <Button color="success" className="waves-effect waves-light ml-1"

                  onClickCapture={handleSubmit((data, e) => submitSchoolData(data, e))} >
                  Save <i className="ri-save-line align-bottom ml-1"></i>
                </Button>
                <Button color="danger" className="waves-effect waves-light ml-1" onClick={() => { reset(); history.goBack() }} >
                  Go Back <i className="ri-close-circle-line align-bottom ml-1"></i>
                </Button>

              </FormGroup>
            </Row>
          </CardBody>
        </Card>

      </Col>

    </React.Fragment>
  );
}

export default AddStudentForm;