import React, { Component, useState, useEffect } from 'react';
import { Container, Row } from "reactstrap";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';




//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import AddStudentForm from './StudentAddEdit';

const StudentAdd = ({ title }) => {
    const [breadcrumbItems] = useState([
        { title: "Schools", link: "/schools" },
        { title: "Enter School Details", link: "#" },
    ]);



    useEffect(() => {

    }, [breadcrumbItems, title]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Add/Edit School Details" breadcrumbItems={breadcrumbItems} />

                    <AddStudentForm />


                </Container>
            </div>
        </React.Fragment>
    );
}


export default StudentAdd;