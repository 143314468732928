import React, { Component, useState, useEffect } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, CardBody, Card } from "reactstrap";
import classnames from 'classnames';



//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import StudentList from '../StudentList/StudentList';
import UserList from '../User/UserList';
import AddSchoolForm from '../SchoolAdd/SchoolAddEdit';
import { Link, useHistory, useParams } from "react-router-dom";
import { getSchoolById } from '../../services/schools';
import { storage } from '../../utils/utils';


const StarterPageSchoolDetails = ({ title }) => {
    const { id } = useParams()

    const [schoolInfo, setSchoolInfo] = useState()
    const [activeTab, setActiveTab] = useState("1")
    const [breadcrumbItems] = useState([
        { title: "Schools", link: "/schools" },
        { title: "Dashboard", link: "#" },
    ]);



    useEffect(() => {

    }, [breadcrumbItems, title]);

    useEffect(() => {
        getSchoolById(id).then((res) => {
            console.log("res", JSON.parse(JSON.stringify(res)))
            setSchoolInfo(JSON.parse(JSON.stringify(res)))
            storage.setSchool(res)

        })
    }, [])

    useEffect(() => {

        getSchoolById(id).then((res) => {
            console.log("res", JSON.parse(JSON.stringify(res)))
            setSchoolInfo(JSON.parse(JSON.stringify(res)))
            storage.setSchool(res)

        })

    }, [activeTab])


    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Schools" breadcrumbItems={breadcrumbItems} />
                    {console.log("schoolInfo", schoolInfo)}

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <h5 className={classnames({ active: activeTab === '-11' }, " p-3 font-weight-bold text-uppercase")}

                                >   <i className="ri-community-line  align-bottom mr-1"></i> {schoolInfo?.name}</h5>
                                <CardBody className="pt-0">

                                    <Nav tabs className="nav-tabs-custom mb-4">

                                        <NavItem>
                                            <NavLink onClick={() => { toggleTab('1'); }} className={classnames({ active: activeTab === '1' }, "font-weight-bold p-3")}>Students</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink onClick={() => { toggleTab('2'); }} className={classnames({ active: activeTab === '2' }, "p-3 font-weight-bold")}>Users</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink onClick={() => { toggleTab('3'); }} className={classnames({ active: activeTab === '3' }, " p-3 font-weight-bold")}>Settings</NavLink>
                                        </NavItem>




                                    </Nav>
                                    {activeTab == 1 && schoolInfo && < StudentList schoolInfo={schoolInfo} />}
                                    {activeTab == 2 && schoolInfo && < UserList schoolInfo={schoolInfo} />}
                                    {activeTab == 3 && schoolInfo && <AddSchoolForm schoolInfo={schoolInfo} />}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
}

// class StarterPageSchoolDetails1 extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             breadcrumbItems: [
//                 { title: "Colorbenz", link: "#" },
//                 { title: "Dashboard", link: "#" },
//             ],
//         }
//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="page-content">
//                     <Container fluid>

//                         <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />

//                     </Container>
//                 </div>
//             </React.Fragment>
//         );
//     }
// }

export default StarterPageSchoolDetails;