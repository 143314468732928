import React from "react";
import { Route, Redirect } from "react-router-dom";
import Parse from 'parse';
const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	title,
	...rest
}) => (
	<Route
		{...rest}
		render={props => {

			if (isAuthProtected && !Parse.User.current()) {
				return (
					<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
				);
			}

			return (
				<Layout>
					<Component pageTitle={title}  {...props} />
				</Layout>
			);
		}}
	/>
);

export default AppRoute;

