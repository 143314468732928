import React, { useState } from 'react';
import { Col, Input, Button, } from "reactstrap";
import { ListBox } from 'primereact/listbox';
import {
    useQuery,
    useMutation,
    useQueryClient,
} from 'react-query'

// Data Table
import { FilterMatchMode } from "primereact/api";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const OrderedStudnetCardGrid = ({ value, loading, ...props }) => {


    const [filters1, setFilters1] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },

    });


    const onGlobalFilterChange1 = (e) => {

        const value = e.target.value;
        let _filters1 = filters1 ? { ...filters1 } : {};
        _filters1['global'].value = value;

        setFilters1(_filters1);

    }


    const fullNameTemplate = (rowData) => {
        let fName = rowData.get("studentname") ? rowData.get("studentname") : ""
        let mName = rowData.get("fathername") ? rowData.get("fathername") : "";
        let lName = rowData.get("surname") ? rowData.get("surname") : ""
        return <React.Fragment>
            <div>
                {/* {console.log("img data", rowData.get("photo")?._url)} */}
                {fName + " " + mName + " " + lName}
            </div>
        </React.Fragment>
    }

    const imageBodyTemplate = (rowData, { rowIndex }) => {
        let photoNo = rowData.get("photono")
        let noPhotoUrl = "'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'"
        return <div className='text-center' key={"imgDiv" + rowIndex}>
            <img
                src={rowData.get("photo") ? rowData.get("photo")?._url : noPhotoUrl}
                onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                alt={"Sorry"}
                width={50}
                height={50}
                className="product-image mb-1" />

        </div>
    }

    return (
        <React.Fragment>
            <div className="search-box ml-0 mt-2" >
                <div className="position-relative">
                    <Input type="text" className="form-control-sm rounded" id="sdasd" placeholder="Search by class, name , phone etc  ..."
                        onChange={(e) => onGlobalFilterChange1(e)} />
                    <i className="mdi mdi-magnify search-icon" style={{ lineHeight: "30px" }}></i>
                </div>
            </div>

            <DataTable
                // value={selectedOrderDetails && JSON.parse(JSON.stringify(selectedOrderDetails))}
                value={value}
                loading={loading}
                paginator responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
                paginatorPosition={'both'}
                filters={filters1}
                emptyMessage="No record found."

            >

                <Column field="image" header="Photo" body={imageBodyTemplate} headerStyle={{ width: '4rem' }} ></Column>

                <Column body={fullNameTemplate} header="Name" ></Column>
                <Column body={(el) => el.get("phone1")} filterField={(el) => el.get("phone1")} header="Phone1" ></Column>
                <Column body={(el) => el.get("phone2")} filterField={(el) => el.get("phone2")} header="Phone2"></Column>
                <Column field='class' body={(el) => el.get("class")} header="class" filterField={(el) => el.get("class")} ></Column>
                {/* Columns are in hiiden mode to achive global search  */}
                <Column body={(el) => el.get("studentname")} filterField={(el) => el.get("studentname")} hidden ></Column>
                <Column body={(el) => el.get("fathername")} filterField={(el) => el.get("fathername")} hidden></Column>
                <Column body={(el) => el.get("surname")} filterField={(el) => el.get("surname")} hidden></Column>
                <Column body={(el) => el.get("grno")} filterField={(el) => el.get("grno")} hidden></Column>
                <Column body={(el) => el.get("rollno")} filterField={(el) => el.get("rollno")} hidden></Column>





            </DataTable>
        </React.Fragment>
    )

}

export default OrderedStudnetCardGrid;
