import axios from "axios";
// import { authenticationService } from "./_services/authentication.service";
//import { url } from './shared/basePath'




// const key = authenticationService.currentUserValue != null ? authenticationService.currentUserValue.ApiKey : "";
 const url = "https://624f6b89bdda77e9a9bdf428.mockapi.io/";

const instance = axios.create({baseURL: url});

// instance.defaults.headers['ApiKey'] = key;
 instance.defaults.headers['Cache-Control'] = "no-cache";


export default instance;